<!--劳动者实名认证-->
<template>
  <div class="container">
    <div class="container_content">
      <!--表单-->
      <div class="container_content_form">
        <van-form>
          <van-cell-group inset>
            <van-field
              name="真实姓名"
              label="真实姓名"
              v-model="trueName"
              placeholder="请输入"
              input-align="right"
              :rules="[{ required: true, message: '请填写真实姓名' }]"
            />
            <van-field
              name="身份证号"
              label="身份证号"
              v-model="idCard"
              placeholder="请输入"
              input-align="right"
              :rules="[{ required: true, message: '请填写身份证号' }]"
            />
            <van-field
              v-model="sex"
              type="text"
              readonly="true"
              name="性别"
              label="性别"
              placeholder="请选择"
              input-align="right"
              right-icon="arrow-down"
              @click="showSexPicer = true"
              :rules="[{ required: true, message: '请填写性别' }]"
            />
            <van-field
                name="联系方式"
                label="联系方式"
                v-model="phone"
                placeholder="请输入"
                input-align="right"
                :rules="[{ required: true, message: '请填写联系方式' }]"
            />
          </van-cell-group>
        </van-form>
      </div>
      <!--图片-->
      <div class="container_content_image">
        <!--身份证正面-->
        <div class="container_content_image_item">
          <div class="container_content_image_item_upload">
            <!-- 外部边框-->
            <div class="container_content_image_item_upload_demo">
              <van-uploader
                accept="image/jpeg,image/png,image/jpg"
                v-model="fileList"
                :max-count="1"
                :max-size="50 * 1024 * 1024"
                :after-read="afterRead"
                @oversize="onOversize"
                @delete="deleteImage"
                :preview-size="[300, 210]"
              >
                <div class="container_content_image_item_upload_demo_pic"></div>
              </van-uploader>
            </div>
          </div>
          <!--底下的文字-->
          <div class="container_content_image_item_upload_text">
            上传身份证人像面
          </div>
        </div>
        <!--身份证反面-->
        <div class="container_content_image_item">
          <div class="container_content_image_item_upload">
            <!-- 外部边框-->
            <div class="container_content_image_item_upload_demo">
              <van-uploader
                accept="image/jpeg,image/png,image/jpg"
                v-model="reverseFileList"
                :max-count="1"
                :max-size="50 * 1024 * 1024"
                :after-read="reverseAfterRead"
                @oversize="onOversize"
                @delete="deleteReverseImage"
                :preview-size="[300, 210]"
              >
                <div
                  class="container_content_image_item_upload_demo_reverse"
                ></div>
              </van-uploader>
            </div>
          </div>
          <!--底下的文字-->
          <div class="container_content_image_item_upload_text">
            上传身份证国徽面
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 底部按钮 -->
  <div class="container_btn">
    <van-button @click="sub" :disabled="clickStatus === '0'"
      >上传认证信息</van-button
    >
  </div>
  <van-popup v-model:show="showSexPicer" position="bottom">
    <van-picker
      :columns="sexColumns"
      @confirm="onConfirm"
      @cancel="showSexPicer = false"
    />
  </van-popup>
</template>
<script setup name="WorkerAuth">
import { uploadFiles } from "@/api/upload";
import { getUserInfo, workerAuth } from "@/api/user";
import ImageCompressor from "@/utils/ImageCompressor";
import { ref } from "@vue/reactivity";
import { Dialog, Toast } from "vant";
import { useRouter } from "vue-router";
const sex = ref("");
const showSexPicer = ref(false);
const sexColumns = ["男", "女"];
const onConfirm = (value) => {
  sex.value = value;
  showSexPicer.value = false;
};
const router = useRouter();
const trueName = ref("");
const idCard = ref("");
const phone = ref("");
//按钮是否可点击 0 不可点击   1 可以点击
const clickStatus = ref("1");
//身份证正面
const fileList = ref([]);
const files = ref([]);
const deleteImage = (file, detail) => {
  files.value.splice(detail.index, 1);
};
//身份证反面
const reverseFileList = ref([]);
const reverseFiles = ref([]);
const deleteReverseImage = (file, detail) => {
  reverseFiles.value.splice(detail.index, 1);
};
const sub = async () => {
  if (trueName.value === "") {
    Toast("请填写真实名称");
    return;
  }
  if (idCard.value === "") {
    Toast("请填写身份证号");
    return;
  }
  if (sex.value === "") {
    Toast("请选择性别");
    return;
  }
  //正则身份证号匹配
  var patt =
    /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  if (!patt.test(idCard.value)) {
    Toast("请输入正确的身份证号");
    return;
  }
  if (phone.value === "") {
    Toast("请填写联系方式");
    return;
  }
  //匹配手机号或座机号
  var patrn = /^1[3456789]\d{9}$/; //手机电话
  var zjdh = /^((0\d{2,3})-)?(\d{7,8})$/; //座机电话
  if (patrn.test(phone.value) == false && zjdh.test(phone.value) == false) {
    Toast("请填写正确的联系方式");
    return;
  }
  if (files.value.length === 0) {
    Toast("请上传身份证人像面");
    return;
  }
  if (reverseFiles.value.length === 0) {
    Toast("请上传身份证国徽面");
    return;
  }
  const data = {
    trueName: trueName.value,
    idCard: idCard.value,
    idCardFont: files.value[0],
    idCardReverse: reverseFiles.value[0],
    sex: sex.value === "男" ? "0" : "1",
    phone:phone.value
  };
  let response = await workerAuth(data);
  if (response != null && response.code === 200) {
    router.go(-1);
  } else {
    Toast(response.msg);
  }
};
//身份证正面
const afterRead = async (file) => {
  let blob = await ImageCompressor(file.file, "blob", 0.1);
  // 此时可以自行将文件上传至服务器
  const formData = new FormData();
  let newFile = new File([blob], file.file.name, {
    type: file.file.type,
    lastModified: Date.now(),
  });
  formData.append("file", newFile);
  let response = await uploadFiles(formData);
  files.value.push(response.url);
};
//身份证反面
const reverseAfterRead = async (file) => {
  let blob = await ImageCompressor(file.file, "blob", 0.1);
  // 此时可以自行将文件上传至服务器
  const formData = new FormData();
  let newFile = new File([blob], file.file.name, {
    type: file.file.type,
    lastModified: Date.now(),
  });
  formData.append("file", newFile);
  let response = await uploadFiles(formData);
  reverseFiles.value.push(response.url);
};

const onOversize = (file) => {
  alert(JSON.stringify(file));
  Toast("文件大小不能超过30m");
};

//获取用户信息
const getUser = async () => {
  let result = await getUserInfo();
  if (result != null && result.code === 200) {
    if (result.data.authStatus != null && result.data.authStatus !== "") {
      //判断按钮状态 可以点击
      if (result.data.authStatus === "0" || result.data.authStatus === "3") {
        clickStatus.value = "1";
      } else {
        clickStatus.value = "0";
      }
      if (result.data.remark != null && result.data.authStatus === "3") {
        //如果审核未通过，弹窗提示
        Dialog.confirm({
          title: "提示",
          message: "您的审核未通过:" + result.data.remark,
        })
          .then(async () => {})
          .catch(async () => {});
      }
    }
    if (result.data.workerInfo != null) {
      //真实名称
      if (
        result.data.workerInfo.trueName != null &&
        result.data.workerInfo.trueName !== ""
      ) {
        trueName.value = result.data.workerInfo.trueName;
      }
      //身份证号
      if (
        result.data.workerInfo.idCard != null &&
        result.data.workerInfo.idCard !== ""
      ) {
        idCard.value = result.data.workerInfo.idCard;
      }
      //性别
      if (result.data.workerInfo.sex !== null) {
        if (result.data.workerInfo.sex === "0") {
          sex.value = "男";
        } else {
          sex.value = "女";
        }
      }
      //联系方式
      if (result.data.workerInfo.phone !== null) {
        phone.value = result.data.workerInfo.phone;
      }
      //身份证正面
      if (
        result.data.workerInfo.idCardFont != null &&
        result.data.workerInfo.idCardFont !== ""
      ) {
        let item = {};
        item.url = result.data.workerInfo.idCardFont;
        fileList.value.push(item);
        files.value.push(result.data.workerInfo.idCardFont);
      }
      //身份证反面
      if (
        result.data.workerInfo.idCardReverse != null &&
        result.data.workerInfo.idCardReverse !== ""
      ) {
        let item = {};
        item.url = result.data.workerInfo.idCardReverse;
        reverseFileList.value.push(item);
        reverseFiles.value.push(result.data.workerInfo.idCardReverse);
      }
    }
  }
};

getUser();
</script>
<style scoped lang="scss">
.container {
  position: relative;
  height: calc(100vh - 120px);
  overflow-y: scroll;
  &_content {
    font-size: 16px;
    color: #757575;
    margin-top: 20px;
    padding-left: 15px;
    padding-right: 15px;

    &_image {
      display: block;
      text-align: center;
      &_item {
        &_upload {
          display: flex;
          justify-content: center;
          margin: 30px 30px 20px 30px;
          &_demo {
            border-radius: 10px;
            border: 1px solid;
            color: #652363;
            width: 100%;
            height: 100%;
            padding: 20px;
            &_pic {
              width: 280px;
              height: 194px;
              background: url("../../assets/images/idcard_font.jpg") no-repeat
                center;
              background-size: cover;
            }
            &_reverse {
              width: 280px;
              height: 194px;
              background: url("../../assets/images/idcard_reverse.jpg")
                no-repeat center;
              background-size: cover;
            }
          }
          &_text {
            font-size: 16px;
            color: #212121;
          }
        }
      }
    }
  }
}
.container_btn {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: center;
  box-shadow: 0px -1px 1px 0px rgba(238, 238, 238, 100);
  font-family: Arial;

  .van-button {
    margin: 10px;
    width: 355px;
    height: 44px;
    border-radius: 6px;
    background-color: #652363;
    text-align: center;
    color: rgba(255, 255, 255, 100);
    font-size: 16px;
    font-family: PingFangSC-semiBold;
    font-weight: bolder;
  }
}
.van-cell {
  padding-left: 0;
  padding-right: 0;
}
.van-cell::after {
  width: 100%;
  display: inline-block;
  left: 0;
}
</style>
