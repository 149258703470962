import request from "@/utils/request";

//获取列表
export function getNoticeList(params, data) {
  return request({
    url: "/appreq/notice/getNoticeList",
    method: "post",
    params: params,
    data,
  });
}

//添加阅读量
export function addReadCount(data) {
  return request({
    url: "/appreq/notice/addReadCount",
    method: "post",
    data,
  });
}

//获取详情
export function getNoticeDetail(id) {
  return request({
    url: "/appreq/notice/getNoticeDetail/" + id,
    method: "get",
  });
}

//获取详情
export function getTopNotice() {
  return request({
    url: "/appreq/notice/getTopNotice",
    method: "get",
  });
}
