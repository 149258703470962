<!--用户协议-->
<template>
  <div class="container">
    <!--标题-->
    <div class="container_title">
      和谐关系用户协议
    </div>
    <!--内容-->
    <div class="container_content">
      &emsp;&emsp;重要提示：在使用和谐关系平台之前，请您务必认真阅读本使用规则及附件（简称“本规则”），须充分理解各条款内容，特别是涉及免除或限制责任条款。当您进入和谐关系平台，接受或使用平台各项服务时，即表示您已知悉并同意本规则及附件的全部内容，如您不同意本规则中任何条款，应当立即停止使用。<br />
      &emsp;&emsp;您必须具备完全行为能力才能使用平台服务。若您不具备前述行为能力，则您本人及您的监护人、代理人应依法承担因此导致的全部法律责任。<br />
      &emsp;&emsp;我们的平台：和谐关系平台（简称“和谐关系”），是由华劳集团股份有限公司开发并运行的。<br />
      一、用户注册<br />
      1.1自行注册：劳动者或企业在首次使用或接受平台服务前，按照平台提示信息独立完成注册。<br />
      1.2用户完成注册后，即可享受平台提供的各项服务，同时应当遵守并履行本规则各项义务。用户以平台认可的其他方式接受或使用和谐关系平台视为已完成注册。<br />
      二、服务项目<br />
      2.1信息服务<br />
      2.1.1平台为用户提供纠纷调节服务。<br />
      2.1.2平台可以对其提供的服务予以变更，且提供的服务内容可以随时变更，用户将会收到关于服务变更的通知。<br />
      三、用户义务<br />
      3.1用户使用或接受平台服务时，应当遵守平台秩序，承担以下义务：<br />
      3.1.1用户须向平台提供真实合法的有效信息， 如因提供的信息虚假、错误、不准确、不完整，所导致的服务及信息偏差、失误，平台不承担任何责任；由此引起的任何损失由用户自行承担。<br />
      3.1.2用户应当妥善保管自己的账号，不应在没有取得授权的情况下使用他人账号。也不应擅自允许其他第三方试用自己的账号，范围规定产生的后果由用户自行承担。<br />
      3.1.3用户不得利用平台骚扰、妨碍他人或从事任何违法或侵权行为（包括但不限于恶意提交申请、频繁撤销申请等影响平台或其他用户的行为）。<br />
      3.1.4用户不得影响平台的正常运行，不得进行危害平台服务或影响平台数据的行为。<br />
      3.1.5当平台提出合理请求时，用户应根据平台的请求提供身份、资质相关信息及资料。<br />
      3.1.6用户在使用平台服务过程中，所产生硬件、其他应用软件、网络通讯服务及其它方面的费用，均由其独自承担。<br />
      3.2用户违反平台管理或平台规则，平台有权采取相关处理措施，包括但不限于：信息删除、屏蔽处理、限制使用、终止服务、查封或注销账号等。如造成平台损失，用户还应赔偿平台包括但不限于A、直接经济损失B、商誉损失、对外垫付或赔偿款项、和解或调解款项、律师费、差旅费、诉讼费用、行政罚款等间接经济损失。<br />
      3.3用户同意平台向其发送商业性短信息，用以通知、提醒、确认平台使用的相关内容及优惠信息等。<br />
      四、各方责任<br />
      4.1除本规则载明责任外，平台不对争议及遭受的损失承担责任。<br />
      4.2鉴于互联网服务特殊性，以及平台信息来源于用户提供，故平台无法逐一审查信息的真实性、准确性，对此用户自己应审慎判决并独立做出决定。对因此导致的损害，除非平台故意造成外，平台不承担任何责任。<br />
      4.3用户在本服务中或通过本服务所传送、发布的任何内容并不反映或代表，也不得被视为反映或代表平台的观点、立场或政策，平台对此不承担任何责任。<br />
      五、知识产权<br />
      5.1平台包含的所有内容，包括但不限于文本、图形、标志及软件的所有权均属华劳集团股份有限公司所有，受知识产权等法律法规的保护，您在使用服务过程中不得以任何形式侵害所有人权利。<br />
      5.2未经平台允许，您不得复制或发送任何平台内容，不得修改平台相关程序或据此创建衍生产品或竞争产品。<br />
      5.3除另有特别声明外，平台提供本服务时所依托软件的专利权及其他知识产权均归平台所有。<br />
      六、规则文本、通知、变更和终止<br />
      6.1平台所有通过官网、微信公众号发布的实施细则、公告、通知、产品或流程说明等均视为本规则的不可分割的组成部分，与本规则具有同等法律效力。<br />
      6.2本规则一经发布即生效。平台可以通过官网、微信、短信息等方式对本规则内容的发布、修改、增加、废止等作出公布或通知，也可以某些功能进行限制或终止使用，用户应当随时关注平台公示信息。规则内容一经公布或通知即替代原规则内容发生效力，如用户不同意发布或变更的内容，应立即停止使用本平台的相关服务；若用户继续使用平台服务，则视为同意发布或变更事项。<br />
      6.3出现以下情况时，平台有权限制或终止向用户提供服务:<br />
      6.3.1提供的手机号码不存在或无法接收信息，且没有其他方式可以进行联系。<br />
      6.3.2本规则变更时，明示并通知平台不愿接受新的变更内容的<br />
      6.3.3连续180天没有使用平台服务的。<br />
      6.3.4存在违法或违约行为的。<br />
      6.3.5其他为维护平台运营秩序采取的限制或终止服务措施。<br />
      6.4平台在限制或终止提供服务后，仍享有下列权利：<br />
      6.4.1继续保存用户的注册信息及使用平台服务信息。<br />
      6.4.2就用户的违法或违约行为主张法律责任。<br />
      七、法律适用与管辖<br />
      7.1本规则之效力、解释、变更、执行与争议解决均适用中华人民共和国大陆地区法律，如无相关法律规定的，则应参照通用商业惯例和（或）行业惯例。<br />
      7.2因使用平台服务产生争议，应充分友好协商处理；若协商不成，应以平台运营商即华劳集团股份有限公司住所地人民法院为管辖法院。<br />
      7.3本规则任一条款被废止或被视为无效的，不影响本规则其余条款的有效性。本规则的最终解释权归平台运营方华劳集团股份有限公司所有。<br />
      相关释义：<br />
      平台服务：指基于互联网、通讯技术，包含官网、官方客服电话、第三方网页链接、微信以及其他平台认可的服务方式在内的信息服务。<br />
      帐号：指用户进入和使用平台，以及接受平台服务的账号。<br />
      注册号：指用户用以登录账号的号码。<br />
      八、争议解决<br />
      本协议履行过程中发生的争议，各方应友好协商解决，协商未成的，各方一致同意将争议提交平台所在地人民法院诉讼解决。<br />

    </div>
  </div>


</template>
<script setup name="protocol">

</script>
<style scoped lang="scss">
.container {
  position: relative;
  height:  calc(100vh - 120px);
  overflow-y: scroll;
  &_title{
    font-weight: bold;
    margin-top: 20px;
    font-size:18px;
    text-align: center;
    color:#212121;
  }
  &_content{
    font-size:15px;
    color:#757575;
    margin-top:20px;
    padding-left: 30px;
    padding-right:30px;
    line-height: 40px
  }
  &_bottom{
    margin-top:30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    bottom: 0;
    &_check {
      margin-right: 6px;
    }
    &_check img {
      width: 14px;
    }
    &_info {
      color: #9da6c3;
      font-size: 12px;
    }
  }
}
.container_btn{
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: center;
  box-shadow: 0px -1px 1px 0px rgba(238, 238, 238, 100);
  font-family: Arial;

  .van-button {
    margin: 10px;
    width: 355px;
    height: 44px;
    border-radius: 6px;
    background-color: #652363;
    text-align: center;
    color: rgba(255, 255, 255, 100);
    font-size: 16px;
    font-family: PingFangSC-semiBold;
    font-weight: bolder;
  }
}
</style>
