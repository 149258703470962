import request from "@/utils/request";

//获取验证码
export function getSmsCode(query) {
  return request({
    url: "/appreq/user/sendsms",
    headers: {
      isToken: false,
    },
    method: "get",
    params: query,
  });
}

//登录
export function login(data) {
  return request({
    url: "/appreq/user/login",
    headers: {
      isToken: false,
    },
    method: "post",
    data,
  });
}

//选择身份
export function changeIdentity(data) {
  return request({
    url: "/appreq/user/changeIdentity",
    method: "post",
    data,
  });
}

//更新企业信息
export function updateCompanyInfo(data) {
  return request({
    url: "/appreq/company/updateCompanyInfo",
    method: "post",
    data,
  });
}

//更新劳动者信息
export function updateWorkerInfo(data) {
  return request({
    url: "/appreq/worker/updateWorkerInfo",
    method: "post",
    data,
  });
}

//获取用户信息
export function getUserInfo() {
  return request({
    url: "/appreq/user/getUserInfo",
    method: "get",
  });
}

//更换昵称或头像
export function updateAppUser(data) {
  return request({
    url: "/appreq/user/updateAppUser",
    method: "post",
    data,
  });
}

//注销
export function delUser() {
  return request({
    url: "/appreq/user/delUser",
    method: "delete",
  });
}

//劳动者实名认证
export function workerAuth(data) {
  return request({
    url: "/appreq/worker/auth",
    method: "post",
    data
  });
}

//企业实名认证
export function companyAuth(data) {
  return request({
    url: "/appreq/company/auth",
    method: "post",
    data
  });
}
// 获取授权
export function authorize(query) {
  return request({
    url: "/appreq/user/authorize",
    headers: {
      isToken: false,
    },
    method: "get",
    params: query,
  });
}
// h5微信登录
export function mpLogin(data) {
  return request({
    url: "/appreq/user/mpLogin",
    headers: {
      isToken: false,
    },
    method: "post",
    data,
  });
}
// 微信登录绑定手机号
export function bindPhone(data) {
  return request({
    url: "/appreq/user/bindPhone",
    headers: {
      isToken: false,
    },
    method: "post",
    data,
  });
}
