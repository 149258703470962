<template>
  <div class="container">
    <!--顶部搜索-->
    <div class="container_search">
      <div class="container_search_pos">
        <van-search
          shape="round"
          v-model="value"
          readonly="true"
          @click="toSearch"
          placeholder="搜索您想要的内容"
        />
      </div>
    </div>
    <!--banner图-->
    <div class="container_banner">
      <img :src="img == null ? defaultImg : img" alt="" />
    </div>
    <!--标题-->
    <div class="container_title">
      <!--左边的竖线-->
      <div class="container_title_line"></div>
      <!--右侧的文字-->
      <div class="container_title_text">劳动法相关培训课程</div>
    </div>
    <!--课程-->
    <div class="container_class" v-if="!isempty">
      <!--每一个课程-->
      <div
        class="container_class_item"
        @click="toDetail(item)"
        v-for="(item, index) in list"
        :key="index"
      >
        <!--名字-->
        <div class="container_class_item_name">{{ item.name }}</div>
        <!--课时-->
        <div class="container_class_item_num">{{ item.classNum }}课时</div>
      </div>
    </div>
    <van-empty description="暂无信息" v-if="isempty" />
  </div>
</template>
<script setup name="Fawu">
import { getBannerImg, getLawTrainingClass } from "@/api/lawtraining";
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
const isempty = ref(false);
const router = useRouter();
//分类list
const list = ref([]);
//图片
const img = ref(null);

//默认图片路径
const defaultImg = ref(
  "https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/adjust/20220921/1663744552713727.png"
);

const getClassList = async () => {
  let result = await getLawTrainingClass();
  if (result != null && result.code === 200) {
    list.value = result.data;
  }
  if (list.value.length === 0) {
    isempty.value = true;
  }
};
getClassList();
const toSearch = () => {
  router.push({
    path: "/fawuSearch",
  });
};

const toDetail = (data) => {
  console.error(data.name);
  router.push({
    path: "/fawuList",
    query: {
      id: data.id,
      num: data.num,
      name: data.name,
      classNum: data.classNum,
      introduce: data.introduce,
    },
  });
};

//获取banner图
const getImg = async () => {
  let result = await getBannerImg();
  if (result != null && result.code === 200) {
    if (result.data != null) {
      if (result.data.url != null) {
        img.value = result.data.url;
      }
    }
  }
};
getImg();
</script>
<style scoped lang="scss">
.container {
  position: relative;
  height: 93vh;
  overflow-y: scroll;
  //搜索
  &_search {
    width: 100%;
    &_pos {
      padding: 20px 5px 0px 5px;
    }
  }
  //轮播图
  &_banner {
    padding: 10px 15px 0 15px;
    img {
      width: 100%;
    }
  }
  //标题
  &_title {
    display: flex;
    flex-direction: row;
    background-color: rgba(250, 250, 250, 100);
    margin-top: 20px;
    padding: 20px 0 15px 15px;
    &_line {
      width: 4px;
      height: 18px;
      border-radius: 1px;
      margin-top: 1px;
      background-color: #652363;
    }
    &_text {
      margin-left: 5px;
      padding: 0;
      color: #212121;
      font-size: 18px;
      text-align: left;
    }
  }
  //课程
  &_class {
    margin: 5px 15px 15px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &_item {
      width: 37%;
      border-radius: 4px;
      background-color: rgba(250, 250, 250, 100);
      text-align: left;
      padding: 15px 15px 15px 15px;
      margin-left: 15px;
      margin-top: 15px;
      &_name {
        color: #212121;
        text-align: left;
        font-size: 14px;
      }
      &_num {
        margin-top: 15px;
        display: inline-block;
        background-color: #ffffff;
        padding: 5px;
        font-size: 12px;
        color: #9e9e9e;
      }
    }
  }
}
</style>
