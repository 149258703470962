import request from "@/utils/request";

//获取当前用户在第几步
export function getCurrentProgress(query) {
  return request({
    url: "/appreq/progress/getCurrentProgress",
    method: "get",
    params: query,
  });
}

//添加或更新注册步骤
export function addProgress(data) {
  return request({
    url: "/appreq/progress/addProgress",
    method: "post",
    data,
  });
}
