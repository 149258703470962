<template>
  <div class="container">
    <div class="container_content">
      <!--关于认证-->
      <div class="container_content_about">
        <van-collapse v-model="activeNames">
          <van-collapse-item name="about">
            <template #title>
              <div class="container_content_about_title">关于认证</div>
            </template>
            <div class="container_content_about_text">
              在您提交认证资料后，工作人员会第一时间进行审核，由于认证人数较多，一般认证时间不超过3个工作日。如果认证不通过，我们会反馈给您不通过的项目及整改建议，修改后，再次提交即可。
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
      <!--反馈问题-->
      <div class="container_content_feedback" @click="toFeedBack">反馈问题</div>
      <van-divider />
    </div>
  </div>
</template>
<script setup name="HelpAndFeedBack">
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
const activeNames = ref(["other"]);
const router = useRouter();
const toFeedBack = () => {
  router.push({
    path: "/feedBack",
  });
};
</script>
<style lang="scss" scoped>
.container {
  padding-top: 15px;
  &_content {
    &_about {
      &_title {
        padding-right: 15px;
        font-size: 16px;
        color: #212121;
      }
      &_text {
        background: #fafafa;
        padding: 15px;
        border-radius: 4px;
      }
    }
    &_feedback {
      margin-top: 15px;
      padding-left: 15px;
      color: #652363;
      font-size: 16px;
    }
  }
}
</style>
