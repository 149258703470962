<template>
  <div class="container">
    <div class="container_body">
      <input
        v-model="username"
        class="container_body_input"
        placeholder="请输入手机号"
        name="username"
      />
      <div class="container_body_code">
        <input
          v-model="code"
          class="container_body_input"
          placeholder="请输入验证码"
          name="username"
        />
        <div class="container_body_code_getcode" @click="getCode">
          {{ loginCodeText }}
        </div>
      </div>
      <div class="container_body_btn">
        <van-button type="warning" size="large" @click="handleLogin"
          >确定</van-button
        >
      </div>
    </div>
  </div>
</template>
<script setup name="Bindphone">
import { bindPhone, getSmsCode } from "@/api/user";
import { ref } from "@vue/reactivity";
import { Toast } from "vant";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { setToken, setUserType } from "../utils/auth";
const store = useStore();
const username = ref("");
const code = ref("");
const loginCodeText = ref("获取验证码");
const router = useRouter();
const route = useRoute();
const h5OpenId = route.query.h5OpenId;
const type = route.query.type;
// const toLogin = async (code,utype) => {
//   let result = await mpLogin({code:code,type:utype});
//   if (result != null && result.code === 200) {
//     if(result.data.token){
//       setToken(result.data.token);
//       setUserType(result.data.user.type);
//       store.commit("setUserType", result.data.user.type);
//       router.push({ name: "Home" });
//     }else{
//       h5OpenId.value = result.data.user.h5OpenId
//     }
//   }
// }
// function getUrlData(){
//   let url = window.location.href;
//   console.log(url)
//   let cs = url.split('?')[1]; 
//   let arr = cs.split('&');
//   let codeRes = arr[0].substring(5,arr[0].length);
//   type.value = arr[1].substring(6,7);
//   console.log(codeRes);
//   toLogin(codeRes,type.value);
// }
// getUrlData();
const getCode = async () => {
  if (username.value === "") {
    Toast("请输入手机号");
    return;
  }
  if (username.value.length > 11) {
    Toast("请输入正确的手机号");
    return;
  }
  //正则手机号匹配
  var patt = /^[1][3-9][\d]{9}/;
  if (!patt.test(username.value)) {
    Toast("请输入正确的手机号");
    return;
  }

  if (loginCodeText.value !== "获取验证码") {
    Toast("验证码已发送，请注意查收");
    return;
  }

  var timer;
  const params = { phone: username.value };
  let result = await getSmsCode(params);
  if (result.code === 200) {
    var ss = 59;
    timer = setInterval(function () {
      ss--;
      loginCodeText.value = "验证码(" + ss + "s)";
      if (ss <= 0) {
        clearInterval(timer);
        loginCodeText.value = "获取验证码";
      }
    }, 1000);
  } else {
    clearInterval(timer);
    loginCodeText.value = "获取验证码";
  }
};

const handleLogin = async () => {
  if (username.value === "") {
    Toast("请输入手机号");
    return;
  }
  if (code.value === "") {
    Toast("请输入验证码");
    return;
  }
  const data = { phone: username.value, code: code.value,h5OpenId:h5OpenId,type:type };
  let result = await bindPhone(data);
  if (result != null && result.code === 200) {
    setToken(result.data.token);
    setUserType(result.data.user.type);
    store.commit("setUserType", result.data.user.type);
    router.push({ name: "Home" });
  }
};


</script>
<style scoped lang="scss">
.container {
  background-color: white;
  &_body {
    padding: 30px 30px 0 30px;
    &_input {
      border-radius: 5px;
      background-color: rgba(250, 250, 250, 100);
      text-align: left;
      border: none;
      height: 60px;
      width: 100%;
      text-indent: 20px;
    }
    &_code {
      margin-top: 20px;
      position: relative;
      &_getcode {
        position: absolute;
        right: 20px;
        top: 16px;
        line-height: 30px;
        color: #652363;
        font-size: 14px;
        padding-left: 15px;
        border-left: #652363 2px solid;
      }
    }
    &_btn {
      width: 100%;
      margin-top: 20px;
    }
    &_bottom {
      text-align: center;
      margin-top: 40px;
      //position: fixed;
      //bottom: 20px;
      //left: 75px;
      li {
        list-style: none;
        img {
          height: 16px;
          vertical-align: middle;
          margin-right: 6px;
        }
      }
    }
  }
}
.van-button--large {
  height: 60px;
  background: #652363;
  border-radius: 5px;
  border-color: #652363;
  width: 320px;
}
.van-checkbox {
  justify-content: center;
}
</style>
