import request from "@/utils/request";

//查看我的发布列表
export function getMyWorkerApplyList(query) {
  return request({
    url: "/appreq/workerapply/getMyWorkerApplyList",
    method: "get",
    params: query,
  });
}

//向redis提交信息
export function submitApplyToRedis(data) {
  return request({
    url: "/appreq/workerapply/submitApplyToRedis",
    method: "post",
    data,
  });
}

//从redis中读取存入的申请信息
export function getWorkerDetailByRedis() {
  return request({
    url: "/appreq/workerapply/getWorkerDetailByRedis",
    method: "get",
  });
}

//最后一步提交，从redis中读取数据并提交
export function submitApplyLast(data) {
  return request({
    url: "/appreq/workerapply/submitApplyLast",
    method: "post",
    data,
  });
}

//redis删除
export function delWorkerApplyDetailByRedis() {
  return request({
    url: "/appreq/workerapply/delWorkerApplyDetailByRedis",
    method: "delete",
  });
}

//根据id查询详情
export function getWorkerDetail(id) {
  return request({
    url: "/appreq/workerapply/getWorkerDetail/" + id,
    method: "get",
  });
}

//从数据库中删除
export function delWorkerApplyDetail(id) {
  return request({
    url: "/appreq/workerapply/delWorkerApplyDetail/" + id,
    method: "delete",
  });
}


//根据年份分组查询我的申请
export function getMyWokerApplyListByYear() {
  return request({
    url: "/appreq/workerapply/getMyApplyListByYear",
    method: "get",
  });
}

//获取纠纷分类
export function getApplyType() {
  return request({
    url: "/appreq/dict/getApplyType",
    method: "get",
  });
}
