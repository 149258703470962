<!--劳动者调解建议书第三步(提交请求事项)-->
<template>
  <div class="container">
    <!--标题-->
    <div class="container_title">劳动调解申请书（3/4）</div>
    <!--内容-->
    <div class="container_content">
      <!--请求事项-->
      <div
        class="container_content_top"
        v-for="(item, index) in formList"
        :key="index"
      >
        <!--请求事项+示例-->
        <div class="container_content_top_title">
          <div class="container_content_top_title_left">
            请求事项{{ index + 1 }}
          </div>
          <div
            class="container_content_top_title_right"
            v-if="index === 0"
            @click="requestShow = true"
          >
            示例
          </div>
          <div
            class="container_content_top_title_right"
            v-if="index > 0"
            @click="del(index)"
          >
            <span style="color: red">删除</span>
          </div>
        </div>
        <!--输入框 -->
        <div class="container_content_top_text fa-bg">
          <van-field
            v-model="item.request"
            rows="5"
            autosize
            type="textarea"
            placeholder="请简明扼要的写明具体要求"
          />
        </div>
      </div>
      <!--添加请求事项-->
      <div class="container_content_add" @click="add">
        <van-icon name="plus" color="#652363" />
        <div class="container_content_add_text">添加请求事项</div>
      </div>
      <van-divider />
      <!--      事实和理由-->
      <div class="container_content_bottom">
        <div class="container_content_bottom_title">
          <div class="container_content_bottom_title_left">事实和理由</div>
          <div
            class="container_content_bottom_title_right"
            @click="reasonShow = true"
          >
            示例
          </div>
        </div>
      </div>
      <!--输入框 -->
      <div class="container_content_top_text fa-bg">
        <van-field
          v-model="reason"
          rows="5"
          autosize
          type="textarea"
          placeholder="请输入事实和理由"
        />
      </div>
    </div>
  </div>
  <!-- 底部按钮 -->
  <div class="container_btn">
    <van-button @click="sub">下一步</van-button>
  </div>

  <!--  请求事项示例-->
  <VanDialog
    v-model:show="requestShow"
    title=""
    width="80%"
    @confirm="requestShow = false"
    confirmButtonColor="#652363"
  >
    <div class="dialog_container">
      1、请求裁决被申请人向申请人支付x年x月x日至x年x月x日的(如工资等）,共计xx
      元。（注：准确填写时间段、款项、名称、金额等）<br />
      2、请求事项多项的，请分条填写。
    </div>
  </VanDialog>

  <!--  原因示例-->
  <VanDialog
    v-model:show="reasonShow"
    title=""
    width="80%"
    @confirm="reasonShow = false"
    confirmButtonColor="#652363"
  >
    <div class="dialog_reason_container">
      &nbsp;&nbsp;&nbsp;&nbsp;申请人于xx年x月x日到被申请人处工作，负责xx工作，工资约定每月xx钱，双方
      （签订/末签订）劳动合同，（缴纳/未缴纳）社会保险，于xx年x月x日因(双方协商、申请人辞职、被申请人单方面辞退等）解除劳动关系。工作期间，被申请人存在xx(如拖欠工资等）情況，现申请调解。
      <br />&nbsp;&nbsp;&nbsp;&nbsp;要求一次性养老补助的，事实和理由部分需写明退休时间，退休单位；要求工伤、医疗、生育待遇损失的，需写明发生工伤、医疗、生育的时间及产生费用的情况。
    </div>
  </VanDialog>
</template>
<script setup name="WorkerApplyThird">
import { getWorkerDetailByRedis, submitApplyToRedis } from "@/api/workerApply";
import { ref } from "@vue/reactivity";
import { Toast } from "vant";
import { useRouter } from "vue-router";
const reason = ref("");
//请求事项提示
const requestShow = ref(false);
//请求事项list
const formList = ref([]);

//事实和理由
const reasonShow = ref(false);
const router = useRouter();

const sub = async () => {
  for (let i = 0; i < formList.value.length; i++) {
    if (formList.value[i].request === "") {
      Toast("请填写请求事项" + (i + 1));
      return;
    }
  }
  if (reason.value === "") {
    Toast("请填写事实和理由");
    return;
  }
  const params = {
    requestList: formList.value,
    reason: reason.value,
  };
  let result = await submitApplyToRedis(params);
  if (result != null && result.code === 200) {
    router.push({
      path: "/workerApplyFourth",
    });
  } else {
    Toast(result.msg);
  }
};

//点击增加请求事项
const add = () => {
  formList.value.push({
    request: "",
  });
};

//删除请求事项
const del = (index) => {
  if (formList.value.length === 1) {
    Toast("最少保留一个请求事项");
    return;
  }
  formList.value.splice(index, 1);
};

//初始化列表
const initList = () => {
  if (formList.value.length === 0) {
    formList.value.push({
      request: "",
    });
  }
};
//获取详情
const getApplyDetail = async () => {
  let result = await getWorkerDetailByRedis();
  if (result != null && result.code === 200) {
    if (result.data != null) {
      if (
        result.data.requestList != null &&
        result.data.requestList.length > 0
      ) {
        for (let i = 0; i < result.data.requestList.length; i++) {
          let item = {};
          if (result.data.requestList[i].request != null) {
            item.request = result.data.requestList[i].request;
          }
          formList.value.push(item);
        }
      }

      if (result.data.reason != null) {
        reason.value = result.data.reason;
      }
    }
    initList();
  } else {
    initList();
  }
};

getApplyDetail();
</script>
<style scoped lang="scss">
.container {
  position: relative;
  height: calc(100vh - 120px);
  overflow-y: scroll;
  &_title {
    font-weight: bold;
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
    color: #212121;
  }
  &_content {
    font-size: 16px;
    color: #757575;
    margin-top: 50px;
    padding-left: 15px;
    padding-right: 15px;
    //请求事项部分
    &_top {
      &_title {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        &_left {
          font-size: 16px;
          color: #212121;
        }
        &_right {
          font-size: 16px;
          color: #652363;
        }
      }
      //文本
      &_text {
        margin-top: 20px;
        background-color: #fafafa;
      }
    }

    //添加按钮
    &_add {
      display: flex;
      margin-top: 20px;
      justify-content: center;
      margin-bottom: 50px;
      &_text {
        color: #652363;
        margin-left: 6px;
        font-size: 16px;
      }
    }

    &_bottom {
      margin-top: 25px;
      &_title {
        display: flex;
        justify-content: space-between;
        &_left {
          font-size: 16px;
          color: #212121;
        }
        &_right {
          font-size: 16px;
          color: #652363;
        }
      }
      //文本
      &_text {
        margin-top: 20px;
        background-color: #fafafa;
      }
      //添加按钮
      &_add {
        display: flex;
        margin-top: 20px;
        justify-content: center;
        margin-bottom: 50px;
        &_text {
          color: #652363;
          margin-left: 6px;
          font-size: 16px;
        }
      }
    }
  }
}
.container_btn {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: center;
  box-shadow: 0px -1px 1px 0px rgba(238, 238, 238, 100);
  font-family: Arial;

  .van-button {
    margin: 10px;
    width: 355px;
    height: 44px;
    border-radius: 6px;
    background-color: #652363;
    text-align: center;
    color: rgba(255, 255, 255, 100);
    font-size: 16px;
    font-family: PingFangSC-semiBold;
    font-weight: bolder;
  }
  .van-field {
    font-size: 16px;
  }
}
.dialog_container {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
  position: relative;
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  line-height: 30px;
}

.dialog_reason_container {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
  position: relative;
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  line-height: 30px;
}
</style>
