<!--劳动者调解建议书第四步(提交图片)-->
<template>
  <div class="mynavbar">
    <van-nav-bar
      title="调解申请"
      border="false"
      left-arrow
      @click-left="clickLeft"
    />
  </div>
  <div class="container">
    <!--标题-->
    <div class="container_title">劳动调解申请书（4/4）</div>
    <!--内容-->
    <div class="container_content">
      <div class="container_content_title"><span class="container_content_title_span">*</span>申请人身份证人像面</div>
      <div class="container_content_image">
        <van-uploader
          accept="image/jpeg,image/png,image/jpg"
          v-model="fontFileList"
          :max-count="1"
          :max-size="50 * 1024 * 1024"
          :after-read="(file) => afterRead(file, '1')"
          @oversize="onOversize"
          @delete="(file, detail) => deleteImage(file, detail, '1')"
        />
      </div>
      <van-divider />

      <div class="container_content_title"><span class="container_content_title_span">*</span>申请人身份证国徽面</div>
      <div class="container_content_image">
        <van-uploader
          accept="image/jpeg,image/png,image/jpg"
          v-model="reverseFileList"
          :max-count="1"
          :max-size="50 * 1024 * 1024"
          :after-read="(file) => afterRead(file, '2')"
          @oversize="onOversize"
          @delete="(file, detail) => deleteImage(file, detail, '2')"
        />
      </div>
      <van-divider />

      <div class="container_content_title"><span class="container_content_title_span">*</span>被申请人工商注册信息资料</div>
      <div class="container_content_image">
        <van-uploader
          accept="image/jpeg,image/png,image/jpg"
          v-model="gongshangFileList"
          :max-count="3"
          :max-size="50 * 1024 * 1024"
          :after-read="(file) => afterRead(file, '3')"
          @oversize="onOversize"
          @delete="(file, detail) => deleteImage(file, detail, '3')"
        />
      </div>
      <van-divider />

      <div class="container_content_title">劳动合同</div>
      <div class="container_content_image">
        <van-uploader
          accept="image/jpeg,image/png,image/jpg"
          v-model="hetongFileList"
          :max-count="3"
          :max-size="50 * 1024 * 1024"
          :after-read="(file) => afterRead(file, '4')"
          @oversize="onOversize"
          @delete="(file, detail) => deleteImage(file, detail, '4')"
        />
      </div>
      <van-divider />

      <div class="container_content_title">暂住证</div>
      <div class="container_content_image">
        <van-uploader
          accept="image/jpeg,image/png,image/jpg"
          v-model="zanzhuFileList"
          :max-count="3"
          :max-size="50 * 1024 * 1024"
          :after-read="(file) => afterRead(file, '5')"
          @oversize="onOversize"
          @delete="(file, detail) => deleteImage(file, detail, '5')"
        />
      </div>
      <van-divider />

      <div class="container_content_title">工作证</div>
      <div class="container_content_image">
        <van-uploader
          accept="image/jpeg,image/png,image/jpg"
          v-model="gongzuoFileList"
          :max-count="3"
          :max-size="50 * 1024 * 1024"
          :after-read="(file) => afterRead(file, '6')"
          @oversize="onOversize"
          @delete="(file, detail) => deleteImage(file, detail, '6')"
        />
      </div>
      <van-divider />

      <div class="container_content_title">厂牌</div>
      <div class="container_content_image">
        <van-uploader
          accept="image/jpeg,image/png,image/jpg"
          v-model="changpaiFileList"
          :max-count="3"
          :max-size="50 * 1024 * 1024"
          :after-read="(file) => afterRead(file, '7')"
          @oversize="onOversize"
          @delete="(file, detail) => deleteImage(file, detail, '7')"
        />
      </div>
      <van-divider />

      <div class="container_content_title">工卡</div>
      <div class="container_content_image">
        <van-uploader
          accept="image/jpeg,image/png,image/jpg"
          v-model="gongkaFileList"
          :max-count="3"
          :max-size="50 * 1024 * 1024"
          :after-read="(file) => afterRead(file, '8')"
          @oversize="onOversize"
          @delete="(file, detail) => deleteImage(file, detail, '8')"
        />
      </div>
      <van-divider />

      <div class="container_content_title">工资表</div>
      <div class="container_content_image">
        <van-uploader
          accept="image/jpeg,image/png,image/jpg"
          v-model="gongziFileList"
          :max-count="3"
          :max-size="50 * 1024 * 1024"
          :after-read="(file) => afterRead(file, '9')"
          @oversize="onOversize"
          @delete="(file, detail) => deleteImage(file, detail, '9')"
        />
      </div>
      <van-divider />

      <div class="container_content_title">入职登记表</div>
      <div class="container_content_image">
        <van-uploader
          accept="image/jpeg,image/png,image/jpg"
          v-model="dengjiFileList"
          :max-count="3"
          :max-size="50 * 1024 * 1024"
          :after-read="(file) => afterRead(file, '10')"
          @oversize="onOversize"
          @delete="(file, detail) => deleteImage(file, detail, '10')"
        />
      </div>
      <van-divider />

      <div class="container_content_title">押金收据</div>
      <div class="container_content_image">
        <van-uploader
          accept="image/jpeg,image/png,image/jpg"
          v-model="yajinFileList"
          :max-count="3"
          :max-size="50 * 1024 * 1024"
          :after-read="(file) => afterRead(file, '11')"
          @oversize="onOversize"
          @delete="(file, detail) => deleteImage(file, detail, '11')"
        />
      </div>
      <van-divider />

      <div class="container_content_title">被处罚凭证</div>
      <div class="container_content_image">
        <van-uploader
          accept="image/jpeg,image/png,image/jpg"
          v-model="chufaFileList"
          :max-count="3"
          :max-size="50 * 1024 * 1024"
          :after-read="(file) => afterRead(file, '12')"
          @oversize="onOversize"
          @delete="(file, detail) => deleteImage(file, detail, '12')"
        />
      </div>
      <van-divider />

      <div class="container_content_title">
        被开除、除名、辞退、解除或终止劳动关系通知
      </div>
      <div class="container_content_image">
        <van-uploader
          accept="image/jpeg,image/png,image/jpg"
          v-model="kaichuFileList"
          :max-count="3"
          :max-size="50 * 1024 * 1024"
          :after-read="(file) => afterRead(file, '13')"
          @oversize="onOversize"
          @delete="(file, detail) => deleteImage(file, detail, '13')"
        />
      </div>
      <van-divider />

      <div class="container_content_title">其他</div>
      <div class="container_content_image">
        <van-uploader
          accept="image/jpeg,image/png,image/jpg"
          v-model="otherFileList"
          :max-count="3"
          :max-size="50 * 1024 * 1024"
          :after-read="(file) => afterRead(file, '14')"
          @oversize="onOversize"
          @delete="(file, detail) => deleteImage(file, detail, '14')"
        />
      </div>
    </div>

    <!--同意对勾-->
    <div class="container_bottom">
      <!--      <div class="container_bottom_check">
        <img src="../../../assets/images/choosed.png" alt="" />
      </div>
      <div class="container_bottom_info">以上资料真实有效,字迹清晰可见</div>-->
      <van-checkbox v-model="checked"
        ><span style="color: #9da6c3; font-size: 13px"
          >以上资料真实有效,字迹清晰可见</span
        ></van-checkbox
      >
    </div>
  </div>
  <!-- 底部按钮 -->
  <div class="container_btn">
    <van-button @click="sub">完成</van-button>
  </div>
</template>
<script setup name="WorkerApplyFourth">
import { uploadFiles } from "@/api/upload";
import {
getWorkerDetailByRedis,
submitApplyLast,
submitApplyToRedis,
} from "@/api/workerApply";
import ImageCompressor from "@/utils/ImageCompressor";
import { ref } from "@vue/reactivity";
import { Toast } from "vant";
import { useRouter } from "vue-router";
const checked = ref(false);
const router = useRouter();
//申请人人像面
const fontFileList = ref([]);
//申请人国徽面
const reverseFileList = ref([]);
//申请人工商注册信息资料
const gongshangFileList = ref([]);
//劳动合同
const hetongFileList = ref([]);
//暂住证
const zanzhuFileList = ref([]);
//工作证
const gongzuoFileList = ref([]);
//厂牌
const changpaiFileList = ref([]);
//工卡
const gongkaFileList = ref([]);
//工资卡
const gongziFileList = ref([]);
//入职等级表
const dengjiFileList = ref([]);
//押金收据
const yajinFileList = ref([]);
//被处罚凭证
const chufaFileList = ref([]);
//被开除、除名、辞退、解除
const kaichuFileList = ref([]);
//其他
const otherFileList = ref([]);
//图片集合用于往后端传递参数
const picList = ref([]);

/**以下这些创建的目的是为了分类存储图片**/
//申请人人像面
const fontFiles = ref([]);
//申请人国徽面
const reverseFiles = ref([]);
//申请人工商注册信息资料
const gongshangFiles = ref([]);
//劳动合同
const hetongFiles = ref([]);
//暂住证
const zanzhuFiles = ref([]);
//工作证
const gongzuoFiles = ref([]);
//厂牌
const changpaiFiles = ref([]);
//工卡
const gongkaFiles = ref([]);
//工资卡
const gongziFiles = ref([]);
//入职等级表
const dengjiFiles = ref([]);
//押金收据
const yajinFiles = ref([]);
//被处罚凭证
const chufaFiles = ref([]);
//被开除、除名、辞退、解除
const kaichuFiles = ref([]);
//其他
const otherFiles = ref([]);
const sub = async () => {
  if (fontFiles.value.length === 0) {
    Toast("请上传身份证人像面");
    return;
  }
  if (reverseFiles.value.length === 0) {
    Toast("请上传身份证国徽面");
    return;
  }
  if (gongshangFiles.value.length === 0) {
    Toast("请上传被申请人工商注册信息资料");
    return;
  }
  if (!checked.value) {
    Toast("请先确认以上资料真实有效,字迹清晰可见");
    return;
  }
  /* if (hetongFiles.value.length === 0) {
    Toast("请上传劳动合同");
    return;
  }*/
  /*  if (zanzhuFiles.value.length === 0) {
    Toast("请上传暂住证");
    return;
  }*/
  /* if (gongzuoFiles.value.length === 0) {
    Toast("请上传工作证");
    return;
  }*/
  /*if (changpaiFiles.value.length === 0) {
    Toast("请上传厂牌");
    return;
  }*/
  /*if (gongkaFiles.value.length === 0) {
    Toast("请上传工卡");
    return;
  }*/
  /*if (gongkaFiles.value.length === 0) {
    Toast("请上传工资表");
    return;
  }*/
  /*if (dengjiFiles.value.length === 0) {
    Toast("请上传工资表");
    return;
  }*/
  /* if (yajinFiles.value.length === 0) {
    Toast("请上传押金收据");
    return;
  }*/
  /*if (chufaFiles.value.length === 0) {
    Toast("请上传处罚凭证");
    return;
  }*/
  /* if (kaichuFiles.value.length === 0) {
    Toast("请上传被开除、除名、辞退、解除或终止劳动关系通知");
    return;
  }*/
  //开始上传
  fontFiles.value.forEach(function (element) {
    let item = {};
    item.type = "1";
    item.url = element;
    picList.value.push(item);
  });
  reverseFiles.value.forEach(function (element) {
    let item = {};
    item.type = "2";
    item.url = element;
    picList.value.push(item);
  });
  gongshangFiles.value.forEach(function (element) {
    let item = {};
    item.type = "3";
    item.url = element;
    picList.value.push(item);
  });
  if (hetongFiles.value.length > 0) {
    hetongFiles.value.forEach(function (element) {
      let item = {};
      item.type = "4";
      item.url = element;
      picList.value.push(item);
    });
  }

  if (zanzhuFiles.value.length > 0) {
    zanzhuFiles.value.forEach(function (element) {
      let item = {};
      item.type = "5";
      item.url = element;
      picList.value.push(item);
    });
  }

  if (gongzuoFiles.value.length > 0) {
    gongzuoFiles.value.forEach(function (element) {
      let item = {};
      item.type = "6";
      item.url = element;
      picList.value.push(item);
    });
  }
  if (changpaiFiles.value.length > 0) {
    changpaiFiles.value.forEach(function (element) {
      let item = {};
      item.type = "7";
      item.url = element;
      picList.value.push(item);
    });
  }
  if (gongkaFiles.value.length > 0) {
    gongkaFiles.value.forEach(function (element) {
      let item = {};
      item.type = "8";
      item.url = element;
      picList.value.push(item);
    });
  }

  if (gongziFiles.value.length > 0) {
    gongziFiles.value.forEach(function (element) {
      let item = {};
      item.type = "9";
      item.url = element;
      picList.value.push(item);
    });
  }

  if (dengjiFiles.value.length > 0) {
    dengjiFiles.value.forEach(function (element) {
      let item = {};
      item.type = "10";
      item.url = element;
      picList.value.push(item);
    });
  }
  if (yajinFiles.value.length > 0) {
    yajinFiles.value.forEach(function (element) {
      let item = {};
      item.type = "11";
      item.url = element;
      picList.value.push(item);
    });
  }

  if (changpaiFiles.value.length > 0) {
    chufaFiles.value.forEach(function (element) {
      let item = {};
      item.type = "12";
      item.url = element;
      picList.value.push(item);
    });
  }

  if (kaichuFiles.value.length > 0) {
    kaichuFiles.value.forEach(function (element) {
      let item = {};
      item.type = "13";
      item.url = element;
      picList.value.push(item);
    });
  }

  if (otherFiles.value.length > 0) {
    otherFiles.value.forEach(function (element) {
      let item = {};
      item.type = "14";
      item.url = element;
      picList.value.push(item);
    });
  }
  const params = {
    picList: picList.value,
  };
  let result = await submitApplyLast(params);
  if (result != null && result.code === 200) {
    router.push({
      path: "/",
    });
  } else {
    Toast(result.msg);
  }
};

const afterRead = async (file, type) => {
  let blob = await ImageCompressor(file.file, "blob", 0.1);
  // 此时可以自行将文件上传至服务器
  const formData = new FormData();
  let newFile = new File([blob], file.file.name, {
    type: file.file.type,
    lastModified: Date.now(),
  });
  formData.append("file", newFile);
  let response = await uploadFiles(formData);
  if (type === "1") {
    fontFiles.value.push(response.url);
  } else if (type === "2") {
    reverseFiles.value.push(response.url);
  } else if (type === "3") {
    gongshangFiles.value.push(response.url);
  } else if (type === "4") {
    hetongFiles.value.push(response.url);
  } else if (type === "5") {
    zanzhuFiles.value.push(response.url);
  } else if (type === "6") {
    gongzuoFiles.value.push(response.url);
  } else if (type === "7") {
    changpaiFiles.value.push(response.url);
  } else if (type === "8") {
    gongkaFiles.value.push(response.url);
  } else if (type === "9") {
    gongziFiles.value.push(response.url);
  } else if (type === "10") {
    dengjiFiles.value.push(response.url);
  } else if (type === "11") {
    yajinFiles.value.push(response.url);
  } else if (type === "12") {
    chufaFiles.value.push(response.url);
  } else if (type === "13") {
    kaichuFiles.value.push(response.url);
  } else if (type === "14") {
    otherFiles.value.push(response.url);
  }
};
const onOversize = () => {
  //alert(JSON.stringify(file));
  Toast("文件大小不能超过30m");
};
const deleteImage = (file, detail, type) => {
  if (type === "1") {
    fontFiles.value.splice(detail.index, 1);
  } else if (type === "2") {
    reverseFiles.value.splice(detail.index, 1);
  } else if (type === "3") {
    gongshangFiles.value.splice(detail.index, 1);
  } else if (type === "4") {
    hetongFiles.value.splice(detail.index, 1);
  } else if (type === "5") {
    zanzhuFiles.value.splice(detail.index, 1);
  } else if (type === "6") {
    gongzuoFiles.value.splice(detail.index, 1);
  } else if (type === "7") {
    changpaiFiles.value.splice(detail.index, 1);
  } else if (type === "8") {
    gongkaFiles.value.splice(detail.index, 1);
  } else if (type === "9") {
    gongziFiles.value.splice(detail.index, 1);
  } else if (type === "10") {
    dengjiFiles.value.splice(detail.index, 1);
  } else if (type === "11") {
    yajinFiles.value.splice(detail.index, 1);
  } else if (type === "12") {
    chufaFiles.value.splice(detail.index, 1);
  } else if (type === "13") {
    kaichuFiles.value.splice(detail.index, 1);
  } else if (type === "14") {
    otherFiles.value.splice(detail.index, 1);
  }
};

const clickLeft = async () => {
  //开始上传
  if (fontFiles.value.length > 0) {
    fontFiles.value.forEach(function (element) {
      let item = {};
      item.type = "1";
      item.url = element;
      picList.value.push(item);
    });
  }

  if (reverseFiles.value.length > 0) {
    reverseFiles.value.forEach(function (element) {
      let item = {};
      item.type = "2";
      item.url = element;
      picList.value.push(item);
    });
  }

  if (gongshangFiles.value.length > 0) {
    gongshangFiles.value.forEach(function (element) {
      let item = {};
      item.type = "3";
      item.url = element;
      picList.value.push(item);
    });
  }

  if (hetongFiles.value.length > 0) {
    hetongFiles.value.forEach(function (element) {
      let item = {};
      item.type = "4";
      item.url = element;
      picList.value.push(item);
    });
  }

  if (zanzhuFiles.value.length > 0) {
    zanzhuFiles.value.forEach(function (element) {
      let item = {};
      item.type = "5";
      item.url = element;
      picList.value.push(item);
    });
  }

  if (gongzuoFiles.value.length > 0) {
    gongzuoFiles.value.forEach(function (element) {
      let item = {};
      item.type = "6";
      item.url = element;
      picList.value.push(item);
    });
  }
  if (changpaiFiles.value.length > 0) {
    changpaiFiles.value.forEach(function (element) {
      let item = {};
      item.type = "7";
      item.url = element;
      picList.value.push(item);
    });
  }
  if (gongkaFiles.value.length > 0) {
    gongkaFiles.value.forEach(function (element) {
      let item = {};
      item.type = "8";
      item.url = element;
      picList.value.push(item);
    });
  }

  if (gongziFiles.value.length > 0) {
    gongziFiles.value.forEach(function (element) {
      let item = {};
      item.type = "9";
      item.url = element;
      picList.value.push(item);
    });
  }

  if (dengjiFiles.value.length > 0) {
    dengjiFiles.value.forEach(function (element) {
      let item = {};
      item.type = "10";
      item.url = element;
      picList.value.push(item);
    });
  }
  if (yajinFiles.value.length > 0) {
    yajinFiles.value.forEach(function (element) {
      let item = {};
      item.type = "11";
      item.url = element;
      picList.value.push(item);
    });
  }

  if (changpaiFiles.value.length > 0) {
    chufaFiles.value.forEach(function (element) {
      let item = {};
      item.type = "12";
      item.url = element;
      picList.value.push(item);
    });
  }

  if (kaichuFiles.value.length > 0) {
    kaichuFiles.value.forEach(function (element) {
      let item = {};
      item.type = "13";
      item.url = element;
      picList.value.push(item);
    });
  }

  if (otherFiles.value.length > 0) {
    otherFiles.value.forEach(function (element) {
      let item = {};
      item.type = "14";
      item.url = element;
      picList.value.push(item);
    });
  }
  const params = {
    picList: picList.value,
  };
  let result = await submitApplyToRedis(params);
  if (result != null && result.code === 200) {
    router.go(-1);
  } else {
    Toast(result.msg);
  }
};

//获取详情
const getApplyDetail = async () => {
  let result = await getWorkerDetailByRedis();
  if (result != null && result.code === 200) {
    if (result.data != null) {
      if (result.data.picList != null && result.data.picList.length > 0) {
        for (let i = 0; i < result.data.picList.length; i++) {
          //身份证正面
          if (result.data.picList[i].type === "1") {
            fontFiles.value.push(result.data.picList[i].url);
            let item = {};
            item.url = result.data.picList[i].url;
            fontFileList.value.push(item);
          }
          //身份证反面
          else if (result.data.picList[i].type === "2") {
            reverseFiles.value.push(result.data.picList[i].url);
            let item = {};
            item.url = result.data.picList[i].url;
            reverseFileList.value.push(item);
          } //工商注册信息
          else if (result.data.picList[i].type === "3") {
            gongshangFiles.value.push(result.data.picList[i].url);
            let item = {};
            item.url = result.data.picList[i].url;
            gongshangFileList.value.push(item);
          } //劳动合同
          else if (result.data.picList[i].type === "4") {
            hetongFiles.value.push(result.data.picList[i].url);
            let item = {};
            item.url = result.data.picList[i].url;
            hetongFileList.value.push(item);
          } //暂住证
          else if (result.data.picList[i].type === "5") {
            zanzhuFiles.value.push(result.data.picList[i].url);
            let item = {};
            item.url = result.data.picList[i].url;
            zanzhuFileList.value.push(item);
          } //工作证
          else if (result.data.picList[i].type === "6") {
            gongzuoFiles.value.push(result.data.picList[i].url);
            let item = {};
            item.url = result.data.picList[i].url;
            gongzuoFileList.value.push(item);
          } //厂牌
          else if (result.data.picList[i].type === "7") {
            changpaiFiles.value.push(result.data.picList[i].url);
            let item = {};
            item.url = result.data.picList[i].url;
            changpaiFileList.value.push(item);
          } //工卡
          else if (result.data.picList[i].type === "8") {
            gongkaFiles.value.push(result.data.picList[i].url);
            let item = {};
            item.url = result.data.picList[i].url;
            gongkaFileList.value.push(item);
          } //工资卡
          else if (result.data.picList[i].type === "9") {
            gongziFiles.value.push(result.data.picList[i].url);
            let item = {};
            item.url = result.data.picList[i].url;
            gongziFileList.value.push(item);
          } //入职登记表
          else if (result.data.picList[i].type === "10") {
            dengjiFiles.value.push(result.data.picList[i].url);
            let item = {};
            item.url = result.data.picList[i].url;
            dengjiFiles.value.push(item);
          } //押金收据
          else if (result.data.picList[i].type === "11") {
            yajinFiles.value.push(result.data.picList[i].url);
            let item = {};
            item.url = result.data.picList[i].url;
            yajinFileList.value.push(item);
          } //被处罚凭证
          else if (result.data.picList[i].type === "12") {
            chufaFiles.value.push(result.data.picList[i].url);
            let item = {};
            item.url = result.data.picList[i].url;
            chufaFileList.value.push(item);
          } //被开除
          else if (result.data.picList[i].type === "13") {
            kaichuFiles.value.push(result.data.picList[i].url);
            let item = {};
            item.url = result.data.picList[i].url;
            kaichuFileList.value.push(item);
          } //其他
          else if (result.data.picList[i].type === "14") {
            otherFiles.value.push(result.data.picList[i].url);
            let item = {};
            item.url = result.data.picList[i].url;
            otherFileList.value.push(item);
          }
        }
      }
    }
  }
};

getApplyDetail();
</script>
<style type="text/css">
.mynavbar .van-nav-bar__content {
  background: #fff;
}
</style>
<style scoped lang="scss">
.container {
  position: relative;
  height: calc(100vh - 120px);
  overflow-y: scroll;
  &_title {
    font-weight: bold;
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
    color: #212121;
  }
  &_content {
    font-size: 16px;
    color: #757575;
    margin-top: 50px;
    padding-left: 15px;
    padding-right: 15px;
    &_title {
      color: #212121;
      font-size: 16px;
      margin-top: 25px;
      &_span{
        color: red!important;
        padding-right: 5px;
      }
    }
    &_image {
      margin-top: 20px;
    }
  }

  &_bottom {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    bottom: 0;
    &_check {
      margin-right: 6px;
    }
    &_check img {
      width: 14px;
    }
    &_info {
      color: #9da6c3;
      font-size: 12px;
    }
  }
}
.container_btn {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: center;
  box-shadow: 0px -1px 1px 0px rgba(238, 238, 238, 100);
  font-family: Arial;

  .van-button {
    margin: 10px;
    width: 355px;
    height: 44px;
    border-radius: 6px;
    background-color: #652363;
    text-align: center;
    color: rgba(255, 255, 255, 100);
    font-size: 16px;
    font-family: PingFangSC-semiBold;
    font-weight: bolder;
  }
}
.van-nav-bar {
  height: 44px;
  line-height: 44px;
  text-align: center;
  font-size: 18px;
  background-color: #ededed;
}
.van-nav-bar__content {
  height: 44px;
  line-height: 44px;
}
.van-checkbox {
  justify-content: center;
}
</style>
