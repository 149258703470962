<template>
  <div class="container">
    <div class="container_top">
      <div class="container_top_title">一站式（企业）劳动争议解决方案服务提供商</div>
      <div class="container_help">
        <div class="container_help_box">
          <div class="container_help_box_btn" style="margin-right: 5px">
            <div class="container_help_box_btn_icon">
              <img src="../assets/images/apply.svg" alt="" />
            </div>
            <div @click="check">
              <div class="container_help_box_btn_title">申请调解</div>
              <div class="container_help_box_btn_desc">提交资料 快速解决</div>
            </div>
          </div>
          <div class="container_help_box_btn" style="margin-left: 5px">
            <div class="container_help_box_btn_icon">
              <img src="../assets/images/telhelp.jpg" alt="" />
            </div>
            <div class="container_help_box_btn_info" @click="call">
              <div class="container_help_box_btn_title">电话求助</div>
              <div class="container_help_box_btn_desc">免费咨询 即时沟通</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container_divider"></div>
    <div class="container_content">
      <div class="container_content_title">
        <div class="container_content_title_left">我的申请</div>
        <div class="container_content_title_right">{{ total }}条</div>
      </div>
      <van-divider />
      <div class="container_content_list">
        <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
          v-if="!isempty"
        >
          <van-list
            v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            ref="listRef"
          >
            <div
              class="container_content_list_item"
              v-for="item in list"
              :key="item"
              :title="item"
              @click="toDetail(item.id)"
            >
              <div class="container_content_list_item_title">
                <div class="container_content_list_item_title_left">
                  {{
                    store.state.userType === "1"
                      ? item.respondentList[0].companyName
                      : item.respondent.name
                  }}
                </div>
                <div class="container_content_list_item_title_right">
                  {{ item.createTime }}
                </div>
              </div>
              <div class="container_content_list_item_desc">
                <span
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                  "
                  >{{
                    item.requestList.length > 0
                      ? item.requestList[0].request
                      : ""
                  }}</span
                >
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
        <van-empty description="暂无信息" v-if="isempty" />
      </div>
    </div>
  </div>
</template>

<script setup name="Home">
import {
delCompanyApplyDetailByRedis,
getCompanyDetailByRedis,
getMyCompanyApplyList,
} from "@/api/companyApply";
import { getCurrentProgress } from "@/api/progress";
import { getUserInfo } from "@/api/user";
import {
delWorkerApplyDetailByRedis,
getMyWorkerApplyList,
getWorkerDetailByRedis,
} from "@/api/workerApply";
import { reactive, ref, toRefs } from "@vue/reactivity";
import { Dialog, Toast } from "vant";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
const isempty = ref(false);
// 总数
const total = ref(0);
const store = useStore();
const list = ref([]);
const router = useRouter();
const loading = ref(false);
const finished = ref(false);
const refreshing = ref(false);
const listRef = ref(null);
listRef.value?.check();
const query = reactive({ queryParams: { pageSize: 10, pageNum: 1 } });
const { queryParams } = toRefs(query);
const onLoad = async () => {
  if (refreshing.value) {
    queryParams.value.pageNum = 1;
    list.value = [];
    refreshing.value = false;
  }
  let result;
  if (store.state.userType === "1") {
    //劳动者
    result = await getMyWorkerApplyList(queryParams.value);
  } else {
    //企业
    result = await getMyCompanyApplyList(queryParams.value);
  }

  if (result?.code === 200) {
    loading.value = false;
    refreshing.value = false;
    if (result.rows.length > 0) {
      list.value = list.value.concat(result.rows);
      queryParams.value.pageNum++;
      finished.value = false;
    } else {
      // 数据全部加载完成
      finished.value = true;
    }
  } else {
    finished.value = true;
    loading.value = false;
  }

  /*  if(result?.code === 200){
    list.value.concat(result.rows)
    total.value = result?.total;
    finished.value = true;
    isempty.value = false;
    if (list.value.length < result?.total) {
      queryParams.value.pageNum++;
    }else {
      loading.value = false;
      finished.value = true;
      isempty.value = true;
    }
    console.error(list.value.length);
  }*/

  /*  for (let i = 0; i < 10; i++) {
    list.value.push(list.value.length + 1);
  }
  loading.value = false;

  if (list.value.length >= 40) {
    finished.value = true;
  }*/
  if (list.value.length === 0) {
    isempty.value = true;
    total.value = list.value.length;
  } else {
    total.value = list.value.length;
  }
};
const onRefresh = () => {
  finished.value = false;
  // 重新加载数据
  // 将 loading 设置为 true，表示处于加载状态
  loading.value = true;
  onLoad();
};
//检查是否可以跳转
const check = async () => {
  let result = await getUserInfo();
  if (result != null && result.code === 200) {
    if (result.data != null) {
      if (result.data.authStatus != null) {
        if (result.data.authStatus === "2") {
          toWorkerApply();
        } else if (result.data.authStatus === "0") {
          Dialog.confirm({
              title: '',
              message:
                '您的账号未认证,不能提交申请,请前往个人中心进行认证',
            })
              .then(() => {
                if(result.data.type === '2'){
                  router.push({
                    path: "/companyAuth"
                  });
                }else{
                  router.push({
                    path: "/workerAuth"
                  });
                }
                
              })
              .catch(() => {
                // on cancel
              });
          // Toast("您的账号未认证,不能提交申请,请前往个人中心进行认证");
        } else if (result.data.authStatus === "1") {
          Toast("您的账号审核中,请耐心等待");
        } else if (result.data.authStatus === "3") {
          Toast("您的账号未审核通过");
        }
      }
    }
  }
};
const toDetail = (id) => {
  if (store.state.userType === "1") {
    //劳动者
    router.push({
      path: "/applyContent",
      query: {
        id,
      },
    });
  } else {
    //企业
    router.push({
      path: "/companyApplyContent",
      query: {
        id,
      },
    });
  }
};
const toWorkerApply = async () => {
  if (store.state.userType === "1") {
    //劳动者
    let result = await getWorkerDetailByRedis();
    if (result.code === 200) {
      //说明已经填写过了，有没有填写完的的
      Dialog.confirm({
        title: "提示",
        message: "您有未完成的申请,是否继续填写?",
      })
        .then(async () => {
          let result = await getWorkerDetailByRedis();
          if (result.code === 200) {
            let data = result.data;
            //判断跳转到哪页
            if (
              data.respondentList == null &&
              data.requestList == null &&
              data.picList == null
            ) {
              //跳转了第二步
              router.push({
                path: "/workerApplySecond",
              });
            } else if (
              data.respondentList != null &&
              data.requestList == null &&
              data.picList == null
            ) {
              //跳转到第三步
              router.push({
                path: "/workerApplyThird",
              });
            } else if (
              data.respondentList != null &&
              data.requestList != null &&
              data.picList == null
            ) {
              //跳转到第四步
              router.push({
                path: "/workerApplyFourth",
              });
            } else if (
              data.respondentList != null &&
              data.requestList != null &&
              data.picList != null
            ) {
              //跳转到第四步
              router.push({
                path: "/workerApplyFourth",
              });
            }
          }
        })
        .catch(async () => {
          //点击了取消
          let result = await delWorkerApplyDetailByRedis();
          if (result.code === 200) {
            router.push({
              path: "/workerApplyBefore",
            });
          }
        });
    } else {
      //说明没有填写过
      router.push({
        path: "/workerApplyBefore",
      });
    }
  } else {
    //企业
    let result = await getCompanyDetailByRedis();
    if (result.code === 200) {
      //说明已经填写过了，有没有填写完的的
      Dialog.confirm({
        title: "提示",
        message: "您有未完成的申请,是否继续填写?",
      })
        .then(async () => {
          let result = await getCompanyDetailByRedis();
          if (result.code === 200) {
            let data = result.data;
            //判断跳转到哪页
            if (
              data.respondent == null &&
              data.requestList == null &&
              data.picList == null
            ) {
              //跳转了第二步
              router.push({
                path: "/companyApplySecond",
              });
            } else if (
              data.respondent != null &&
              data.requestList == null &&
              data.picList == null
            ) {
              //跳转到第三步
              router.push({
                path: "/companyApplyThird",
              });
            } else if (
              data.respondent != null &&
              data.requestList != null &&
              data.picList == null
            ) {
              //跳转到第四步
              router.push({
                path: "/companyApplyFourth",
              });
            } else if (
              data.respondent != null &&
              data.requestList != null &&
              data.picList != null
            ) {
              //跳转到第四步
              router.push({
                path: "/workerApplyFourth",
              });
            }
          }
        })
        .catch(async () => {
          //点击了取消
          let result = await delCompanyApplyDetailByRedis();
          if (result.code === 200) {
            router.push({
              path: "/companyApplyBefore",
            });
          }
        });
    } else {
      //没有填写
      router.push({
        path: "/companyApplyBefore",
      });
    }
  }
};

const call = async () =>{
  let url = 'tel:4008718711';
  window.location.href = url;

}


const getCurrentProgressMethod = async ()=>{
  let resultProgress = await getCurrentProgress();
  if (resultProgress != null && resultProgress.code === 200) {
    if (resultProgress.data != null) {
      if (resultProgress.data.progress != null) {
        if (resultProgress.data.progress === 0) {
          Dialog.alert({
            title: "提示",
            message: "您的信息不完整，请您完善信息",
          })
              .then(async () => {
                //跳转到完善信息
                if (store.state.userType === "1") {
                  //个人
                  router.push({ name: "PersonIdentity" });
                } else if (store.state.userType === "2") {
                  //企业
                  router.push({ name: "CompanyIdentity" });
                }
              })
        }
      } else {
        console.error("进来了1");
        Dialog.alert({
          title: "提示",
          message: "您的信息不完整，请您完善信息",
        })
            .then(async () => {
              //跳转到选择身份页
              router.push({ name: "ChooseIdentity" });
            })
      }
    } else {
      console.error("进来了2");
      //跳转到选择身份页
      Dialog.alert({
        title: "提示",
        message: "您的信息不完整，请您完善信息",
      })
          .then(async () => {
            //跳转到选择身份页
            router.push({ name: "ChooseIdentity" });
          })
    }
  } else {
    console.error("进来了3");
    //跳转到选择身份页
    Dialog.alert({
      title: "提示",
      message: "您的信息不完整，请您完善信息",
    })
        .then(async () => {
          //跳转到选择身份页
          router.push({ name: "ChooseIdentity" });
        })

  }
}

// getCurrentProgressMethod();
</script>
<style lang="scss" scoped>
.container {
  position: relative;
  // height: 100vh;
  // overflow-y: scroll;
  &_top {
    height: 230px;
    background-color: #652363;
    &_title {
      padding-top: 70px;
      line-height: 20px;
      // margin: 0 15px;
      font-size: 18px;
      color: #ffffff;
      font-weight: bold;
      text-align: center;
    }
  }
  &_help {
    margin-top: 30px;
    //position: absolute;
    width: 100%;
    border-radius: 15px 15px 0px 0px;
    background-color: rgba(255, 255, 255, 100);
    // left: 0;
    // top: 110px;
    z-index: 999;
    &_box {
      display: flex;
      flex-direction: row;
      padding: 20px 15px 20px 15px;
      justify-content: center;
      &_btn {
        padding: 20px 10px 20px 10px;
        background: #f6f6f6;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        &_icon {
          margin-right: 10px;
          img {
            height: 24px;
          }
        }
        &_info {
        }
        &_title {
          color: #212121;
          font-size: 16px;
          margin-bottom: 8px;
          font-weight: bold;
        }
        &_desc {
          color: #bdbdbd;
          font-size: 12px;
        }
      }
    }
  }
  &_divider {
    height: 10px;
    width: 100%;
    background-color: #fafafa;
  }
  &_content {
    padding: 20px 15px 20px 15px;
    &_title {
      display: flex;
      justify-content: space-between;
      &_left {
        color: #212121;
        font-size: 16px;
        font-weight: bold;
      }
      &_right {
        color: #652363;
        font-size: 15px;
      }
    }
    &_list {
      height: 50vh;
      overflow-y: scroll;
      &_item {
        border-radius: 2px;
        background-color: rgba(250, 250, 250, 100);
        padding: 15px 15px;
        margin-bottom: 15px;
        &_title {
          display: flex;
          justify-content: space-between;
          &_left {
            font-size: 15px;
            color: #101010;
          }
          &_right {
            color: #652363;
            font-size: 15px;
          }
        }
        &_desc {
          margin-top: 15px;
          color: #652363;
          font-size: 15px;
        }
      }
    }
  }
}
</style>
