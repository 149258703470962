<template>
  <div class="container">
    <div class="container_title">一站式（企业）劳动争议解决方案服务提供商</div>
    <div class="container_img">
      <img src="../../assets/images/chooseiden.png" alt="" />
    </div>
    <div class="container_tip">请选择您的身份</div>
    <div class="container_btns">
      <div class="container_btns_btn">
        <van-button type="primary" size="large" @click="toCompanyIdentity"
          >我是用人单位</van-button
        >
      </div>
      <div class="container_btns_btn">
        <van-button type="warning" size="large" @click="toPersonIdentity"
          >我是劳动者</van-button
        >
      </div>
    </div>
  </div>
</template>
<script setup name="ChooseIdentity">
import { addProgress } from "@/api/progress";
import { changeIdentity } from "@/api/user";
import { useRouter } from "vue-router";
import { setUserType } from "../../utils/auth";
const router = useRouter();
//选择企业身份
const toCompanyIdentity = async () => {
  const params = { type: 2 };
  let result = await changeIdentity(params);
  if (result != null && result.code === 200) {
    setUserType(result.data.type);
    //更新步骤
    const progressParams = { progress: "0" };
    let progressResult = await addProgress(progressParams);
    if (progressResult != null && progressResult.code === 200) {
      router.push({ path: "/companyIdentity" });
    }
  }
};

const toPersonIdentity = async () => {
  const params = { type: 1 };
  let result = await changeIdentity(params);
  if (result != null && result.code == 200) {
    setUserType(result.data.type);
    //更新步骤
    const progressParams = { progress: "0" };
    let progressResult = await addProgress(progressParams);
    if (progressResult != null && progressResult.code === 200) {
      router.push({ path: "/personIdentity" });
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  padding: 44px 20px 0 20px;
  background-color: white;
  &_title {
    color: #652363;
    font-size: 24px;
    text-align: center;
  }
  &_img {
    width: 100%;
    margin-top: 30px;
    img {
      width: 100%;
      height: auto;
    }
  }

  &_tip {
    text-align: center;
    color: #212121;
    font-size: 16px;
  }
  &_btns {
    margin-top: 40px;
    &_btn {
      height: 80px;
      color: #4abdd1;
      margin-bottom: 20px;
    }
  }
}

.van-button--large {
  height: 80px;
  border-radius: 5px;
}
.van-button--warning {
  background: #892e86;
  border-color: #892e86;
}
</style>
