<!--企业调解建议书第二步(被申请人信息)-->
<template>
  <div class="container">
    <!--标题-->
    <div class="container_title">劳动调解申请书（2/4）被申请人信息</div>
    <!--form表单-->
    <div class="container_content">
      <van-form>
        <van-cell-group inset>
          <van-field
            name="姓名"
            label="姓名"
            v-model="name"
            placeholder="请输入(必填)"
            input-align="right"
            :rules="[{ required: true, message: '请填写姓名' }]"
          />

          <van-field
            name="性别"
            label="性别"
            v-model="sex"
            input-align="right"
            placeholder="请选择(必填)"
            readonly="true"
            right-icon="arrow-down"
            @click="showSexPicer = true"
            :rules="[{ required: true, message: '请选择性别' }]"
          />
          <van-field
            name="民族"
            label="民族"
            v-model="nationality"
            input-align="right"
            placeholder="请输入(必填)"
            :rules="[{ required: true, message: '请填写民族' }]"
          />

          <van-field
            name="身份证号"
            label="身份证号"
            v-model="idCard"
            input-align="right"
            placeholder="请输入(必填)"
            :rules="[{ required: true, message: '请填写身份证号' }]"
          />

          <van-field
            name="工作单位"
            label="工作单位"
            v-model="workCompany"
            input-align="right"
            placeholder="请输入(非必填)"
          />

          <van-field
            name="联系电话"
            label="联系电话"
            v-model="phone"
            input-align="right"
            placeholder="请输入(必填)"
            :rules="[{ required: true, message: '请填写联系电话' }]"
          />

          <van-field
            name="省市区"
            label="省市区"
            v-model="area"
            input-align="right"
            placeholder="请选择(必填)"
            readonly="true"
            right-icon="arrow-down"
            @click="showArea = true"
            :rules="[{ required: true, message: '请填写省市区' }]"
          />

          <van-field
            name="详细地址"
            label="详细地址"
            v-model="address"
            input-align="right"
            placeholder="请输入(必填)"
            :rules="[{ required: true, message: '请填写详细地址' }]"
          />

          <van-field
            name="邮政编码"
            label="邮政编码"
            v-model="postCode"
            input-align="right"
            placeholder="请输入(必填)"
            :rules="[{ required: true, message: '请填写邮政编码' }]"
          />
        </van-cell-group>
      </van-form>
    </div>
  </div>
  <!-- 底部按钮 -->
  <div class="container_btn">
    <van-button @click="sub">下一步</van-button>
  </div>
  <!--性别-->
  <van-popup v-model:show="showSexPicer" position="bottom">
    <van-picker
      :columns="sexColumns"
      @confirm="onConfirm"
      @cancel="showSexPicer = false"
    />
  </van-popup>
  <!--省市区-->
  <van-popup v-model:show="showArea" position="bottom">
    <van-area
      :area-list="areaList"
      @confirm="onAreaConfirm"
      @cancel="showArea = false"
    />
  </van-popup>
</template>
<script setup name="CompanyApplySecond">
import {
getCompanyDetailByRedis,
submitApplyToRedis,
} from "@/api/companyApply";
import { areaList } from "@vant/area-data";
import { ref } from "@vue/reactivity";
import { Toast } from "vant";
import { useRouter } from "vue-router";
const name = ref("");
const phone = ref("");
const sex = ref("");
const idCard = ref("");
const nationality = ref("");
const workCompany = ref("");
const area = ref("");
const provinceId = ref("");
const cityId = ref("");
const areaId = ref("");
const address = ref("");
const postCode = ref("");
const showSexPicer = ref(false);
const showArea = ref(false);
const sexColumns = ["男", "女"];
const router = useRouter();
const onConfirm = (value) => {
  sex.value = value;
  showSexPicer.value = false;
};
const sub = async () => {
  if (name.value === "") {
    Toast("请填写姓名");
    return;
  }
  if (sex.value === "") {
    Toast("请选择性别");
    return;
  }
  if (nationality.value === "") {
    Toast("请填写民族");
    return;
  }
  if (idCard.value === "") {
    Toast("请填写身份证号");
    return;
  }
  //正则身份证号匹配
  var patt =
    /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  if (!patt.test(idCard.value)) {
    Toast("请输入正确的身份证号");
    return;
  }
  /*if (workCompany.value === "") {
    Toast("请填写工作单位");
    return;
  }*/
  if (phone.value === "") {
    Toast("请填写联系电话");
    return;
  }
  //匹配手机号或座机号
  var patrn = /^1[3456789]\d{9}$/; //手机电话
  var zjdh = /^((0\d{2,3})-)?(\d{7,8})$/; //座机电话
  if (patrn.test(phone.value) == false && zjdh.test(phone.value) == false) {
    Toast("请填写正确的联系电话");
    return;
  }
  if (area.value === "") {
    Toast("请填写省市区");
    return;
  }
  if (address.value === "") {
    Toast("请填写详细地址");
    return;
  }
  if (postCode.value === "") {
    Toast("请填写邮政编码");
    return;
  }
  const data = {
    name: name.value,
    sex: sex.value === "男" ? "0" : "1",
    nationality: nationality.value,
    idCard: idCard.value,
    phone: phone.value,
    provinceId: provinceId.value,
    cityId: cityId.value,
    areaId: areaId.value,
    address: address.value,
    postCode: postCode.value,
  };
  if (workCompany.value != null) {
    data.workCompany = workCompany.value;
  }
  const params = {
    respondent: data,
  };
  let result = await submitApplyToRedis(params);
  if (result != null && result.code === 200) {
    router.push({
      path: "/companyApplyThird",
    });
  }
};

const onAreaConfirm = (value) => {
  showArea.value = false;
  area.value = value
    .filter((item) => !!item)
    .map((item) => item.name)
    .join("-");
  provinceId.value = value[0].code;
  cityId.value = value[1].code;
  areaId.value = value[2].code;
};

//获取详情
const getApplyDetail = async () => {
  let result = await getCompanyDetailByRedis();
  if (result != null && result.code === 200) {
    if (result.data != null) {
      if (result.data.respondent != null) {
        if (result.data.respondent.name != null) {
          name.value = result.data.respondent.name;
        }
        if (result.data.respondent.sex != null) {
          if (result.data.respondent.sex === "0") {
            sex.value = "男";
          } else {
            sex.value = "女";
          }
        }
        if (result.data.respondent.nationality != null) {
          nationality.value = result.data.respondent.nationality;
        }
        if (result.data.respondent.idCard != null) {
          idCard.value = result.data.respondent.idCard;
        }
        if (result.data.respondent.workCompany != null) {
          workCompany.value = result.data.respondent.workCompany;
        }
        if (result.data.respondent.phone != null) {
          phone.value = result.data.respondent.phone;
        }
        if (result.data.respondent.address != null) {
          address.value = result.data.respondent.address;
        }
        if (result.data.respondent.postCode != null) {
          postCode.value = result.data.respondent.postCode;
        }
        if (result.data.provinceAndCityAndArea != null) {
          area.value = result.data.provinceAndCityAndArea;
        }
        if (result.data.provinceId != null) {
          provinceId.value = result.data.provinceId;
        }
        if (result.data.cityId != null) {
          cityId.value = result.data.cityId;
        }
        if (result.data.areaId != null) {
          areaId.value = result.data.areaId;
        }
      }
    }
  }
};

getApplyDetail();
</script>
<style scoped lang="scss">
.container {
  position: relative;
  height: calc(100vh - 120px);
  overflow-y: scroll;
  &_title {
    font-weight: bold;
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
    color: #212121;
  }
  &_content {
    font-size: 16px;
    color: #757575;
    margin-top: 50px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.container_btn {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: center;
  box-shadow: 0px -1px 1px 0px rgba(238, 238, 238, 100);
  font-family: Arial;

  .van-button {
    margin: 10px;
    width: 355px;
    height: 44px;
    border-radius: 6px;
    background-color: #652363;
    text-align: center;
    color: rgba(255, 255, 255, 100);
    font-size: 16px;
    font-family: PingFangSC-semiBold;
    font-weight: bolder;
  }
}
.van-field {
  color: #212121;
}
.van-cell {
  padding-left: 0;
  padding-right: 0;
}
.van-cell::after {
  width: 100%;
  display: inline-block;
  left: 0;
}
</style>
