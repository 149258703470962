<!--修改头像-->
<template>
  <div class="container">
    <div class="container_title">新头像</div>
    <div class="container_img">
      <van-uploader
        accept="image/jpeg,image/png,image/jpg"
        v-model="fileList"
        :max-count="1"
        :max-size="50 * 1024 * 1024"
        :after-read="afterRead"
        @oversize="onOversize"
        @delete="deleteImage"
      />
    </div>
    <div class="container_tip">请上传小于2M的图片</div>
  </div>
  <!-- 底部按钮 -->
  <div class="container_btn">
    <van-button @click="sub">保存</van-button>
  </div>
</template>
<script setup name="ChangeHeader">
import { uploadFiles } from "@/api/upload";
import { getUserInfo, updateAppUser } from "@/api/user";
import router from "@/router";
import ImageCompressor from "@/utils/ImageCompressor";
import { ref } from "@vue/reactivity";
import { Toast } from "vant";
const fileList = ref([]);
const files = ref([]);
const deleteImage = (file, detail) => {
  files.value.splice(detail.index, 1);
};
const sub = async () => {
  if (files.value.length === 0) {
    Toast({
      message: "请上传新头像",
      icon: "none",
      duration: 2000,
    });
    return false;
  }
  let response = await updateAppUser({ avatar: files.value[0] });
  if (response) {
    router.go(-1);
  }
};
const afterRead = async (file) => {
  let blob = await ImageCompressor(file.file, "blob", 0.1);
  // 此时可以自行将文件上传至服务器
  const formData = new FormData();
  let newFile = new File([blob], file.file.name, {
    type: file.file.type,
    lastModified: Date.now(),
  });
  formData.append("file", newFile);
  let response = await uploadFiles(formData);
  files.value.push(response.url);
};
const onOversize = (file) => {
  alert(JSON.stringify(file));
  Toast("文件大小不能超过30m");
};

//获取用户信息
const getUser = async () => {
  let result = await getUserInfo();
  if (result != null && result.code === 200) {
    if (result.data.avatar != null && result.data.avatar !== "") {
      let item = {};
      item.url = result.data.avatar;
      fileList.value.push(item);
      files.value.push(result.data.avatar);
    }
  }
};
getUser();
</script>
<style scoped lang="scss">
.container {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  &_title {
    font-size: 12px;
    color: #757575;
  }
  &_img {
    margin-top: 20px;
  }
  &_tip {
    margin-top: 15px;
    color: #9e9e9e;
    font-size: 12px;
  }
}
.container_btn {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: center;
  box-shadow: 0px -1px 1px 0px rgba(238, 238, 238, 100);
  font-family: Arial;

  .van-button {
    margin: 10px;
    width: 355px;
    height: 44px;
    border-radius: 6px;
    background-color: #652363;
    text-align: center;
    color: rgba(255, 255, 255, 100);
    font-size: 16px;
    font-family: PingFangSC-semiBold;
    font-weight: bolder;
  }
}
</style>
