<!--修改昵称-->
<template>
  <div class="container">
    <div class="container_title">昵称</div>
    <div class="container_text">
      <van-field
        v-model="nickName"
        placeholder="请输入昵称"
        input-align="left"
        clearable="true"
      />
    </div>
    <div class="container_line">
      <van-divider />
    </div>
    <div class="container_tip">
      设置的昵称会在发布的信息中展示，建议填写真实姓名。
    </div>
  </div>
  <!-- 底部按钮 -->
  <div class="container_btn">
    <van-button @click="sub">保存</van-button>
  </div>
</template>
<script setup name="ChangeNickName">
import { getUserInfo, updateAppUser } from "@/api/user";
import { ref } from "@vue/reactivity";
import { Toast } from "vant";
import { useRouter } from "vue-router";
const nickName = ref("");
const router = useRouter();
const sub = async () => {
  if (nickName.value === "") {
    Toast("请填写昵称");
    return;
  }
  const params = {
    nickName: nickName.value,
  };
  let result = await updateAppUser(params);
  if (result != null && result.code === 200) {
    router.push({ name: "Person" });
  }
};

//获取用户信息
const getUser = async () => {
  let result = await getUserInfo();
  if (result != null && result.code === 200) {
    if (result.data.nickName != null && result.data.nickName !== "") {
      nickName.value = result.data.nickName;
    }
  }
};
getUser();
</script>
<style scoped lang="scss">
.container {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  &_title {
    font-size: 12px;
    color: #757575;
  }
  &_text {
    margin-top: 20px;
  }
  &_tip {
    color: #9e9e9e;
    font-size: 12px;
  }
}
.container_btn {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: center;
  box-shadow: 0px -1px 1px 0px rgba(238, 238, 238, 100);
  font-family: Arial;

  .van-button {
    margin: 10px;
    width: 355px;
    height: 44px;
    border-radius: 6px;
    background-color: #652363;
    text-align: center;
    color: rgba(255, 255, 255, 100);
    font-size: 16px;
    font-family: PingFangSC-semiBold;
    font-weight: bolder;
  }
}
.van-cell {
  padding-left: 0;
  padding-right: 0;
}
.van-field {
  padding-bottom: 0;
}
</style>
