import request from "@/utils/request";

//添加或更新注册步骤
export function add(data) {
  return request({
    url: "/appreq/feedback/add",
    method: "post",
    data,
  });
}
