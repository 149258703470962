<!--设置-->
<template>
  <div class="container">
    <div class="container_content">
      <!--注销账户-->
      <div class="container_content_text" @click="logOff">注销账户</div>
      <div class="container_line">
        <van-divider />
      </div>
      <!--退出登录-->
      <div class="container_content_text" @click="logOut">
        <span style="color: #ff3a2f">退出登录</span>
      </div>
    </div>
    <div class="container_line">
      <van-divider />
    </div>
  </div>
</template>
<script setup name="Setting">
import { delUser } from "@/api/user";
import { removeToken, removeUserType } from "@/utils/auth";
import { Dialog } from "vant";
import { useRouter } from "vue-router";
const router = useRouter();
//注销用户
const logOff = () => {
  Dialog.confirm({
    title: "提示",
    message:
      "注销账户为不可恢复的操作，注销账户后，该账号下的所有数据将被删除。",
  })
    .then(async () => {
      // on confirm
      let response = await delUser();
      if (response != null && response.code === 200) {
        removeToken();
        removeUserType();
        router.push({
          path: "/login",
        });
      }
    })
    .catch(() => {
      // on cancel
    });
};
//退出登录
const logOut = () => {
  removeToken();
  removeUserType();
  router.push({
    path: "/login",
  });
};
</script>
<style scoped lang="scss">
.container {
  margin-top: 25px;
  &_content {
    &_text {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 16px;
    }
  }
}
</style>
