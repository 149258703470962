<template>
  <div class="container">
    <div class="container_top">
      <div class="container_top_content">
        <div class="container_top_content_box">
          <div class="container_top_content_pic" @click="toChangeHeader">
            <img :src="avatar" alt="" />
          </div>
          <div class="container_top_content_info">
            <div class="container_top_content_info_title">{{ nickName }}</div>
            <div class="container_top_content_info_desc">{{ phone }}</div>
          </div>
        </div>

        <div class="container_top_content_edit" @click="toChangeNickName">
          <img src="../assets/images/edit.png" alt="" />
        </div>
      </div>
    </div>
    <div class="container_content">
      <div class="container_content_item">
        <div class="container_content_row" @click="toAuth">
          <div class="container_content_row_left">
            <span><img src="../assets/images/auth.png" alt="" /></span
            ><span style="color: #212121; font-size: 16px; margin-left: 10px"
              >实名认证</span
            >
          </div>
          <div class="container_content_row_right">
            <span style="color: #652363; font-size: 12px; margin-right: 10px">{{
              authStatus
            }}</span
            ><span><img src="../assets/images/arrow.png" alt="" /></span>
          </div>
        </div>
        <van-divider />
      </div>
      <div class="container_content_item">
        <div class="container_content_row" @click="toMyApply">
          <div class="container_content_row_left">
            <span><img src="../assets/images/my_apply.png" alt="" /></span
            ><span style="color: #212121; font-size: 16px; margin-left: 10px"
              >我的申请</span
            >
          </div>
          <div class="container_content_row_right">
            <span
              style="color: #652363; font-size: 12px; margin-right: 10px"
            ></span
            ><span><img src="../assets/images/arrow.png" alt="" /></span>
          </div>
        </div>
        <van-divider />
      </div>
      <div class="container_content_item">
        <div class="container_content_row" @click="toSetting">
          <div class="container_content_row_left">
            <span><img src="../assets/images/setting.png" alt="" /></span
            ><span style="color: #212121; font-size: 16px; margin-left: 10px"
              >设置</span
            >
          </div>
          <div class="container_content_row_right">
            <span
              style="color: #652363; font-size: 12px; margin-right: 10px"
            ></span
            ><span><img src="../assets/images/arrow.png" alt="" /></span>
          </div>
        </div>
        <van-divider />
      </div>

      <div class="container_content_item">
        <div class="container_content_row" @click="toHelpAndFeedBack">
          <div class="container_content_row_left">
            <span><img src="../assets/images/help.png" alt="" /></span
            ><span style="color: #212121; font-size: 16px; margin-left: 10px"
              >帮助与反馈</span
            >
          </div>
          <div class="container_content_row_right">
            <span
              style="color: #652363; font-size: 12px; margin-right: 10px"
            ></span
            ><span><img src="../assets/images/arrow.png" alt="" /></span>
          </div>
        </div>
        <van-divider />
      </div>
    </div>
  </div>
</template>
<script setup name="Person">
import { getUserInfo } from "@/api/user";
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
const router = useRouter();
//头像
const avatar = ref(
  "https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/adjust/20220909/1662689150658451.jpg"
);
//昵称
const nickName = ref("");
//手机号
const phone = ref("");
//认证状态
const authStatus = ref("");
//用户类型
const userType = ref("");

//获取用户信息
const getUser = async () => {
  let result = await getUserInfo();
  if (result != null && result.code === 200) {
    if (result.data.avatar != null && result.data.avatar !== "") {
      avatar.value = result.data.avatar;
    }
    if (result.data.phone != null && result.data.phone !== "") {
      phone.value = result.data.phone;
    }
    if (result.data.authStatus != null) {
      if (result.data.authStatus === "0") {
        authStatus.value = "未认证";
      } else if (result.data.authStatus === "1") {
        authStatus.value = "待审核";
      } else if (result.data.authStatus === "2") {
        authStatus.value = "审核通过";
      } else if (result.data.authStatus === "3") {
        authStatus.value = "审核未通过";
      }
    }
    if (result.data.type === "1") {
      //劳动者
      userType.value = result.data.type;
      if (
        result.data.nickName != null &&
        result.data.nickName !== ""
      ) {
        nickName.value = result.data.nickName;
      } else {
        nickName.value = "劳动者";
      }
    } else {
      userType.value = result.data.type;
      //用人单位
      if (
        result.data.nickName != null &&
        result.data.nickName !== ""
      ) {
        nickName.value = result.data.nickName;
      } else {
        nickName.value = "企业";
      }
    }
  }
};

const toChangeNickName = () => {
  router.push({
    path: "/changeNickName",
  });
};
const toChangeHeader = () => {
  router.push({
    path: "/changeHeader",
  });
};
const toSetting = () => {
  router.push({
    path: "/setting",
  });
};
const toAuth = () => {
  if(userType.value === "1"){
    //劳动者
    router.push({
      path: "/workerAuth",
    });
  }else if(userType.value === "2"){
    //用人单位
    router.push({
      path: "/companyAuth",
    });
  }

};
const toMyApply = () => {
  router.push({
    path: "/myApply",
  });
};
const toHelpAndFeedBack = () => {
  router.push({
    path: "/helpAndFeedBack",
  });
};
getUser();
</script>
<style scoped lang="scss">
.container {
  &_top {
    width: 100%;
    height: 208px;
    background-color: rgba($color: #9da6c3, $alpha: 0.3);
    background-image: url("../assets/images/logintopbg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // padding: 0 20px 0 20px;
    &_content {
      padding: 0 20px 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &_box {
        display: flex;
        align-items: center;
      }
      &_pic {
        img {
          width: 80px;
          border-radius: 50px;
          height: 80px;
        }
      }
      &_info {
        margin-left: 20px;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &_title {
          font-size: 18px;
          color: #101010;
          font-weight: bold;
        }
        &_desc {
          font-size: 14px;
          color: #757575;
        }
      }
      &_edit {
        img {
          height: 18px;
        }
      }
    }
  }
  &_content {
    padding: 30px 15px 0 15px;
    &_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &_left {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        img {
          height: 16px;
          vertical-align: middle;
        }
      }
      &_right {
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
          height: 12px;
        }
      }
    }
  }
}
</style>
