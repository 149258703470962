<template>
  <div class="container">
    <div class="container_body">
      <img src="../assets/images/wx_authorize.svg" alt="" />
      <div>您已授权，自动登录中</div>
    </div>
  </div>
</template>
<script setup name="Authorize">
import { mpLogin } from "@/api/user";
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { setToken, setUserType } from "../utils/auth";
const store = useStore();
const username = ref("");
const code = ref("");
const loginCodeText = ref("获取验证码");
const router = useRouter();
const h5OpenId = ref('');
const type = ref('2')
const toLogin = async (code,utype) => {
  let result = await mpLogin({code:code,type:utype});
  if (result != null && result.code === 200) {
    if(result.data.token){
      setToken(result.data.token);
      setUserType(result.data.user.type);
      store.commit("setUserType", result.data.user.type);
      router.push({ name: "Home" });
    }else{
      h5OpenId.value = result.data.user.h5OpenId;
      router.push({
        path: "/bindphone",
        query: {
          h5OpenId:h5OpenId.value,
          type:utype
        },
      });
    }
  }
}
function getUrlData(){
  let url = window.location.href;
  console.log(url)
  let cs = url.split('?')[1]; 
  let arr = cs.split('&');
  let codeRes = arr[0].substring(5,arr[0].length);
  type.value = arr[1].substring(6,7);
  console.log(codeRes);
  toLogin(codeRes,type.value);
}
getUrlData();

</script>
<style scoped lang="scss">
.container {
  background-color: white;
  text-align: center;
  &_body {
    padding: 120px 30px 0 30px;
    img{
      width: 64px;
    }
    div{
      font-size: 14px;
      color: #101010;
      line-height: 60px;
    }
    
  }
}

</style>
