import request from "@/utils/request";

//获取法务培训课程分类
export function getLawTrainingClass() {
  return request({
    url: "/appreq/lawtraining/getLawTrainingClass",
    method: "get",
  });
}

//根据课程分类查询课程列表
export function getSubjectListByClassId(params, data) {
  return request({
    url: "/appreq/lawtraining/getSubjectListByClassId",
    method: "post",
    params,
    data,
  });
}

//添加学过此分类的人数
export function addPeopleNum(data) {
  return request({
    url: "/appreq/lawtraining/addPeopleNum",
    method: "post",
    data,
  });
}

//根据id获取课程详情
export function getSubjectDetail(id) {
  return request({
    url: "/appreq/lawtraining/getSubjectDetail/" + id,
    method: "get",
  });
}

//根据章节名称查询章节
export function getSubjectByName(params, data) {
  return request({
    url: "/appreq/lawtraining/getSubjectByName",
    method: "post",
    params,
    data,
  });
}

//获取banner图
export function getBannerImg() {
  return request({
    url: "/appreq/lawtraining/getBannerImg",
    method: "get",
  });
}
