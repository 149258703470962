<template>
  <div class="container">
    <div class="container_title">{{ data.name }}</div>
    <div class="container_date">官方课程</div>
    <div class="container_content" v-html="data.content"></div>
  </div>
</template>
<script setup name="FawuContent">
import { useRoute } from "vue-router";
import { getSubjectDetail, addPeopleNum } from "@/api/lawtraining";
import { ref } from "@vue/reactivity";
const route = useRoute();
//课程id
const id = route.query.id;
//课程对象
const data = ref({});

const getDetail = async () => {
  let result = await getSubjectDetail(id);
  if (result != null && result.code === 200) {
    data.value = result.data;
    if (data.value.content != null) {
      data.value.content = data.value.content.replace(
        /<img/g,
        "<img style='max-width:100%;height:auto;'"
      );
    }
  }
};
getDetail();

//添加浏览记录
const add = async () => {
  const params = {
    id: id,
  };
  await addPeopleNum(params);
};
add();
</script>
<style lang="scss" scoped>
.container {
  height: calc(100vh - 70px);
  overflow-y: scroll;
  padding: 20px 15px 0 15px;
  &_title {
    font-size: 18px;
    color: #212121;
    font-weight: bold;
  }
  &_date {
    font-size: 12px;
    color: #424242;
    margin-top: 15px;
  }
  &_content {
    margin-top: 20px;
    font-size: 15px;
    color: #424242;
    p {
      margin-bottom: 15px;
    }
  }
}
</style>
