<template>
  <div class="container">
    <div class="container_tip">请选择您想反馈的问题（必选）</div>
    <div class="container_problem">
      <div
        class="container_problem_item"
        v-for="(item, index) in checkList"
        :key="index"
        @click="onItemClick(index)"
      >
        <div
          :class="[
            item.checked === false
              ? 'container_problem_item_title'
              : 'container_problem_item_title--checked',
          ]"
        >
          {{ item.title }}
        </div>
        <div class="container_problem_item_icon">
          <span v-if="!item.checked"
            ><img src="../../assets/images/uncheck.png" alt="" /></span
          ><span v-if="item.checked"
            ><img src="../../assets/images/choosed.svg" alt=""
          /></span>
        </div>
      </div>
    </div>
    <div class="container_divider">
      <van-divider />
    </div>
    <div class="container_tip">请补充问题和意见</div>
    <div class="container_msg">
      <van-field
        v-model="message"
        rows="4"
        autosize
        type="textarea"
        maxlength="150"
        placeholder="请详细描述问题或意见，以便于我们更好的解决您的问题"
        show-word-limit
      />
    </div>
    <div class="container_divider">
      <van-divider />
    </div>
    <div class="container_tip">请提供相关截图或照片</div>
    <div class="container_upload">
      <van-uploader
        accept="image/jpeg,image/png,image/jpg"
        v-model="fileList"
        multiple
        :max-count="3"
        :max-size="50 * 1024 * 1024"
        :after-read="afterRead"
        @oversize="onOversize"
        @delete="deleteImage"
      />
    </div>
    <div class="container_btn">
      <van-button type="primary" size="large" @click="submit">提交</van-button>
    </div>
  </div>
</template>
<script setup name="FeedBack">
import { add } from "@/api/feedback";
import { uploadFiles } from "@/api/upload";
import ImageCompressor from "@/utils/ImageCompressor";
import { ref } from "@vue/reactivity";
import { Toast } from "vant";
import { useRouter } from "vue-router";
const router = useRouter();
const files = ref([]);
const checkList = ref([]);
const checkIndex = ref(0);
const message = ref("");
//图片list用于往后端传值用
const imgList = ref([]);
checkList.value = [
  { title: "功能异常：功能异常或者不可用", checked: true },
  { title: "产品建议：功能用的不爽，想提建议", checked: false },
  { title: "安全问题：密码、数据、隐私等", checked: false },
];
const onItemClick = (index) => {
  for (let i in checkList.value) {
    if (i == index) {
      checkList.value[i].checked = true;
      checkIndex.value = index;
    } else {
      checkList.value[i].checked = false;
    }
  }
};
const fileList = ref([]);
const submit = async () => {
  if (message.value === "") {
    Toast("请填充问题和意见");
    return;
  }
  //选择的问题
  let str = checkList.value[checkIndex.value].title;
  //图片
  if (files.value.length > 0) {
    for (let j in files.value) {
      let item = {
        url: files.value[j],
      };
      imgList.value.push(item);
    }
  }
  const params = {
    typeName: str,
    description: message.value,
    imgList: imgList.value,
  };
  let result = await add(params);
  if (result != null && result.code === 200) {
    router.go(-1);
    Toast("提交成功");
  } else {
    Toast(result.msg);
  }
};

const afterRead = async (file) => {
  console.log("fiile==", file.file);
  let blob = await ImageCompressor(file.file, "blob", 0.1);
  console.log("newfiile==", blob);
  // 此时可以自行将文件上传至服务器
  const formData = new FormData();
  let newFile = new File([blob], file.file.name, {
    type: file.file.type,
    lastModified: Date.now(),
  });
  formData.append("file", newFile);
  let response = await uploadFiles(formData);
  files.value.push(response.url);
};

const deleteImage = (file, detail) => {
  files.value.splice(detail.index, 1);
};
</script>
<style lang="scss" scoped>
.container {
  height: calc(100vh - 85px);
  overflow-y: scroll;
  padding: 20px 15px 15px 15px;
  &_tip {
    font-size: 16px;
    color: #212121;
    font-weight: bold;
  }
  &_problem {
    margin-top: 20px;
    &_item {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 15px 20px 15px;
      background-color: #fafafa;
      border-radius: 4px;
      &_title {
        color: #9e9e9e;
        font-size: 14px;
      }
      &_title--checked {
        color: #652363;
        font-size: 14px;
      }
      &_icon {
        img {
          height: 16px;
        }
      }
    }
  }
  &_divider {
    margin-top: 20px;
  }
  &_msg {
    margin-top: 20px;
    background-color: #fafafa;
    border-radius: 4px;
  }
  &_upload {
    margin-top: 20px;
  }
  &_btn {
    margin-top: 60px;
  }
}
.van-cell {
  background: #fafafa;
}
</style>
