import request from "@/utils/request";

//获取搜索历史
export function getSearchListRequest(data) {
  return request({
    url: "/appreq/search/getSearchList",
    method: "post",
    data
  });
}

//删除
export function deleteAllRequest(data) {
  return request({
    url: "/appreq/search/deleteAll",
    method: "DELETE",
    data
  });
}

//根据id删除记录
export function deleteSearchByIdRequest(id) {
  return request({
    url: "/appreq/search/deleteSearchById/" + id,
    method: "delete",
  });
}


//添加记录
export function addSearchRequest(data) {
  return request({
    url: "/appreq/search/addSearch",
    method: "post",
    data
  });
}

