<template>
  <div class="container">
    <div class="mytitle">
      <van-collapse
        v-model="activeNames"
        v-for="(item, index) in list"
        :key="index"
      >
        <van-collapse-item
          :title="item.year"
          :name="item.year"
          :value="item.num + '条'"
          title-class="titleClass"
          value-class="valueClass"
        >
          <div
            class="container_item"
            v-for="(itemChild, index) in store.state.userType === '1'
              ? item.workerApplyList
              : item.companyApplyList"
            :key="index"
            @click="toDetail(itemChild.id)"
          >
            <div class="container_item_date">{{ itemChild.monthAndDay }}</div>
            <div class="container_item_content">
               <span style="overflow: hidden;text-overflow: ellipsis;display: -webkit-box;   -webkit-line-clamp: 3;-webkit-box-orient: vertical;">{{
                itemChild.requestList.length > 0
                  ? itemChild.requestList[0].request
                  : ""
                 }}</span>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
    <div class="container_empty" v-if="isempty">
      <van-empty description="暂无数据" />
    </div>
  </div>
</template>
<script setup name="MyApply">
import { getMyCompanyApplyListByYear } from "@/api/companyApply";
import { getMyWokerApplyListByYear } from "@/api/workerApply";
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
const store = useStore();
const router = useRouter();
const isempty = ref(false);
//我的申请列表
const list = ref([]);
//选中的名称
const activeNames = ref([]);
//获取信息
const getMyAppList = async () => {
  if (store.state.userType === "1") {
    //劳动者
    let result = await getMyWokerApplyListByYear();
    if (result != null && result.code === 200) {
      if (result.data != null) {
        list.value = result.data;
        //处理展开
        if (list.value.length > 0) {
          if (list.value[0].year != null) {
            activeNames.value.push(list.value[0].year);
          }
        }
      }
    }
  } else if (store.state.userType === "2") {
    let result = await getMyCompanyApplyListByYear();
    if (result != null && result.code === 200) {
      if (result.data != null) {
        list.value = result.data;
        //处理展开
        if (list.value.length > 0) {
          if (list.value[0].year != null) {
            activeNames.value.push(list.value[0].year);
          }
        }
      }
    }
  }
  if (list.value.length === 0) {
    isempty.value = true;
  }
};
const toDetail = (id) => {
  if (store.state.userType === "1") {
    //劳动者
    router.push({
      path: "/applyContent",
      query: {
        id,
      },
    });
  } else {
    //企业
    router.push({
      path: "/companyApplyContent",
      query: {
        id,
      },
    });
  }
};
getMyAppList();
</script>

<style lang="scss" scoped>
.container {
  height: calc(100vh - 50px);
  overflow-y: scroll;
  &_item {
    margin-bottom: 15px;
    padding: 15px 15px;
    border-radius: 2px;
    background-color: #fafafa;
    &_date {
      font-size: 15px;
      color: #101010;
    }
    &_content {
      font-size: 15px;
      color: #9e9e9e;
    }
  }
}
.titleClass {
  font-size: 18px;
  color: #101010;
  font-weight: bold;
}
.valueClass {
  font-weight: 15px;
  color: #652363;
}
</style>
