import request from "@/utils/request";

//查看我的发布列表
export function getMyCompanyApplyList(query) {
  return request({
    url: "/appreq/companyapply/getMyCompanyApplyList",
    method: "get",
    params: query,
  });
}

//向redis提交数据
export function submitApplyToRedis(data) {
  return request({
    url: "/appreq/companyapply/submitApplyToRedis",
    method: "post",
    data,
  });
}

//从redis中读取存入的申请信息
export function getCompanyDetailByRedis() {
  return request({
    url: "/appreq/companyapply/getCompanyDetailByRedis",
    method: "get",
  });
}

//最后一步提交，从redis中读取数据并提交
export function submitApplyLast(data) {
  return request({
    url: "/appreq/companyapply/submitApplyLast",
    method: "post",
    data,
  });
}

//redis删除
export function delCompanyApplyDetailByRedis() {
  return request({
    url: "/appreq/companyapply/delCompanyApplyDetailByRedis",
    method: "delete",
  });
}

//根据id查询详情
export function getCompanyDetail(id) {
  return request({
    url: "/appreq/companyapply/getCompanyDetail/" + id,
    method: "get",
  });
}

//从数据库中删除
export function delCompanyApplyDetail(id) {
  return request({
    url: "/appreq/companyapply/delCompanyApplyDetail/" + id,
    method: "delete",
  });
}

//根据年份分组查询我的申请
export function getMyCompanyApplyListByYear() {
  return request({
    url: "/appreq/companyapply/getMyApplyListByYear",
    method: "get",
  });
}

//获取纠纷分类
export function getApplyType() {
  return request({
    url: "/appreq/dict/getApplyType",
    method: "get",
  });
}

