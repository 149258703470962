<!--劳动者调解建议书第一步(被申请人信息)-->
<template>
  <div class="container">
    <!--标题-->
    <div class="container_title">劳动调解申请书（2/4）被申请人信息</div>
    <!--form表单-->
    <div class="container_content">
      <div
        class="container_content_form"
        v-for="(item, index) in formList"
        :key="index"
      >
        <div class="container_content_form_title">
          <div class="container_content_form_title_left">
            被申请人{{ index + 1 }}
          </div>
          <div
            class="container_content_form_title_right"
            @click="del(index)"
            v-if="index > 0"
          >
            删除
          </div>
        </div>

        <div class="container_content_form_text">
          <v-form>
            <van-cell-group inset>
              <van-field
                name="企业名称"
                label="企业名称"
                v-model="item.companyName"
                placeholder="请输入(必填)"
                input-align="right"
                :rules="[{ required: true, message: '请填写企业名称' }]"
              />
              <van-field
                name="联系电话"
                label="联系电话"
                v-model="item.phone"
                placeholder="请输入(必填)"
                input-align="right"
                :rules="[{ required: true, message: '请填写联系电话' }]"
              />
              <van-field
                name="法定代表人"
                label="法定代表人"
                v-model="item.companyLar"
                placeholder="请输入(必填)"
                input-align="right"
                :rules="[{ required: true, message: '请填写法定代表人' }]"
              />
              <van-field
                name="职务"
                label="职务"
                v-model="item.job"
                placeholder="请输入(必填)"
                input-align="right"
                :rules="[{ required: true, message: '请填写职务' }]"
              />
              <van-field
                name="单位性质"
                label="单位性质"
                v-model="item.companyNature"
                placeholder="请输入(必填)"
                input-align="right"
                :rules="[{ required: true, message: '请填写单位性质' }]"
              />
              <van-field
                name="省市区"
                label="省市区"
                v-model="item.area"
                input-align="right"
                placeholder="请选择(必填)"
                readonly="true"
                right-icon="arrow-down"
                @click="showAreaMethod(index, true)"
                :rules="[{ required: true, message: '请填写省市区' }]"
              />
              <van-field
                name="详细地址"
                label="详细地址"
                v-model="item.address"
                input-align="right"
                placeholder="请输入(必填)"
                :rules="[{ required: true, message: '请填写详细地址' }]"
              />
              <van-field
                name="邮政编码"
                label="邮政编码"
                v-model="item.postCode"
                input-align="right"
                placeholder="请输入(必填)"
                :rules="[{ required: true, message: '请填写邮政编码' }]"
              />
            </van-cell-group>
          </v-form>
        </div>
      </div>

      <!--添加被申请人-->
      <div class="container_content_add" @click="add">
        <van-icon name="plus" color="#652363" />
        <div class="container_content_add_text">增加被申请人</div>
      </div>
    </div>
  </div>
  <!-- 底部按钮 -->
  <div class="btn">
    <van-button @click="sub">下一步</van-button>
  </div>
  <!--省市区-->
  <van-popup v-model:show="showArea" position="bottom">
    <van-area
      :area-list="areaList"
      @confirm="onAreaConfirm"
      @cancel="showArea = false"
    />
  </van-popup>
</template>
<script setup name="WorkerApplySecond">
import { getWorkerDetailByRedis, submitApplyToRedis } from "@/api/workerApply";
import { areaList } from "@vant/area-data";
import { ref } from "@vue/reactivity";
import { Toast } from "vant";
import { useRouter } from "vue-router";
//被申请人list
const formList = ref([]);
//每一个list的项的下标
const indexItem = ref(0);
/*const provinceId = ref("");
const cityId = ref("");
const areaId = ref("");*/
const showArea = ref(false);
const router = useRouter();
const sub = async () => {
  for (let i = 0; i < formList.value.length; i++) {
    if (formList.value[i].companyName === "") {
      Toast("请填写被申请人" + (i + 1) + "的企业名称");
      return;
    }
    if (formList.value[i].phone === "") {
      Toast("请填写被申请人" + (i + 1) + "的联系电话");
      return;
    }
    //匹配手机号或座机号
    var patrn = /^1[3456789]\d{9}$/; //手机电话
    var zjdh = /^((0\d{2,3})-)?(\d{7,8})$/; //座机电话
    if (
      patrn.test(formList.value[i].phone) == false &&
      zjdh.test(formList.value[i].phone) == false
    ) {
      Toast("请正确填写被申请人" + (i + 1) + "的联系电话");
      return;
    }
    if (formList.value[i].companyLar === "") {
      Toast("请填写被申请人" + (i + 1) + "的法定代表人");
      return;
    }
    if (formList.value[i].job === "") {
      Toast("请填写被申请人" + (i + 1) + "的职务");
      return;
    }
    if (formList.value[i].companyNature === "") {
      Toast("请填写被申请人" + (i + 1) + "的单位性质");
      return;
    }
    if (formList.value[i].area === "") {
      Toast("请填写被申请人" + (i + 1) + "的省市区");
      return;
    }
    if (formList.value[i].address === "") {
      Toast("请填写被申请人" + (i + 1) + "的详细地址");
      return;
    }
    if (formList.value[i].postCode === "") {
      Toast("请填写被申请人" + (i + 1) + "的邮政编码");
      return;
    }
  }
  const params = {
    respondentList: formList.value,
  };
  let result = await submitApplyToRedis(params);
  if (result != null && result.code === 200) {
    router.push({
      path: "/workerApplyThird",
    });
  } else {
    Toast(result.msg);
  }
};

const onAreaConfirm = (value) => {
  console.error(value);
  showArea.value = false;
  formList.value[indexItem.value].area = value
    .filter((item) => !!item)
    .map((item) => item.name)
    .join("-");
  formList.value[indexItem.value].provinceId = value[0].code;
  formList.value[indexItem.value].cityId = value[1].code;
  formList.value[indexItem.value].areaId = value[2].code;
};
//点击增加被申请人
const add = () => {
  formList.value.push({
    companyName: "",
    phone: "",
    companyLar: "",
    job: "",
    companyNature: "",
    provinceId: "",
    cityId: "",
    areaId: "",
    address: "",
    postCode: "",
  });
};

//删除申请人
const del = (index) => {
  if (formList.value.length === 1) {
    Toast("最少保留一个被申请人");
    return;
  }
  formList.value.splice(index, 1);
};

//初始化list
const initList = () => {
  if (formList.value.length === 0) {
    formList.value.push({
      companyName: "",
      phone: "",
      companyLar: "",
      job: "",
      companyNature: "",
      provinceId: "",
      cityId: "",
      areaId: "",
      address: "",
      postCode: "",
    });
  }
};

//展示地区控件
const showAreaMethod = (index, value) => {
  indexItem.value = index;
  showArea.value = value;
};

//获取详情
const getApplyDetail = async () => {
  let result = await getWorkerDetailByRedis();
  if (result != null && result.code === 200) {
    if (result.data != null) {
      if (
        result.data.respondentList != null &&
        result.data.respondentList.length > 0
      ) {
        for (let i = 0; i < result.data.respondentList.length; i++) {
          let item = {};
          if (result.data.respondentList[i].companyName != null) {
            item.companyName = result.data.respondentList[i].companyName;
          }
          if (result.data.respondentList[i].phone != null) {
            item.phone = result.data.respondentList[i].phone;
          }
          if (result.data.respondentList[i].companyLar != null) {
            item.companyLar = result.data.respondentList[i].companyLar;
          }
          if (result.data.respondentList[i].job != null) {
            item.job = result.data.respondentList[i].job;
          }
          if (result.data.respondentList[i].companyNature != null) {
            item.companyNature = result.data.respondentList[i].companyNature;
          }
          if (result.data.respondentList[i].provinceAndCityAndArea != null) {
            item.area = result.data.respondentList[i].provinceAndCityAndArea;
          }
          if (result.data.respondentList[i].areaId != null) {
            item.areaId = result.data.respondentList[i].areaId;
          }
          if (result.data.respondentList[i].provinceId != null) {
            item.provinceId = result.data.respondentList[i].provinceId;
          }
          if (result.data.respondentList[i].cityId != null) {
            item.cityId = result.data.respondentList[i].cityId;
          }
          if (result.data.respondentList[i].address != null) {
            item.address = result.data.respondentList[i].address;
          }
          if (result.data.respondentList[i].postCode != null) {
            item.postCode = result.data.respondentList[i].postCode;
          }
          formList.value.push(item);
        }
      }
    }
    initList();
  } else {
    initList();
  }
};

getApplyDetail();
</script>
<style scoped lang="scss">
.container {
  position: relative;
  height: calc(100vh - 120px);
  overflow-y: scroll;
  &_title {
    font-weight: bold;
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
    color: #212121;
  }
  &_content {
    font-size: 16px;
    color: #757575;
    margin-top: 50px;
    padding-left: 15px;
    padding-right: 15px;
    &_form {
      &_title {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 15px;
        &_left {
          color: #652363;
        }
        &_right {
          color: red;
        }
      }
      &_text {
        margin-top: 15px;
      }
    }

    &_add {
      display: flex;
      margin-top: 50px;
      justify-content: center;
      margin-bottom: 50px;
      &_text {
        color: #652363;
        margin-left: 6px;
        font-size: 16px;
      }
    }
  }
}
.btn {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: center;
  box-shadow: 0px -1px 1px 0px rgba(238, 238, 238, 100);
  font-family: Arial;

  .van-button {
    margin: 10px;
    width: 355px;
    height: 44px;
    border-radius: 6px;
    background-color: #652363;
    text-align: center;
    color: rgba(255, 255, 255, 100);
    font-size: 16px;
    font-family: PingFangSC-semiBold;
    font-weight: bolder;
  }
}
.van-field {
  color: #212121;
}
.van-cell {
  padding-left: 0;
  padding-right: 0;
}
.van-cell::after {
  width: 100%;
  display: inline-block;
  left: 0;
}
</style>
