import request from "@/utils/request";

export function uploadFiles (data) {
  return request({
    url: '/common/upload',
    headers: {
      dataType: false
    },
    method: 'post',
    data: data
  })
}
