'use strict'
// 跨域请求的地址
var domain = '/api'

// 服务端地址
var target = 'https://apihexie.hualaogroup.com/'
// 正式环境
var socketUrl = 'wss://apivol.ygsg.org/wss'
//前端页面的地址
var fontUrl = ''


module.exports = {
  domain: domain,
  target: target,
  socketUrl: socketUrl,
  fontUrl:fontUrl
}



