<template>
  <div class="container">
    <div class="container_title">
      {{ data.noticeTitle }}
    </div>
    <div class="container_date">{{ data.createTime }}</div>
    <div class="container_content" v-html="data.noticeContent"></div>
  </div>
</template>
<script setup name="LawContent">
import { useRoute } from "vue-router";
import { getNoticeDetail, addReadCount } from "@/api/notice";
import { ref } from "@vue/reactivity";
const route = useRoute();
const id = route.query.id;
const data = ref({});

const getDetail = async () => {
  let result = await getNoticeDetail(id);
  if (result != null && result.code === 200) {
    data.value = result.data;
    if (data.value.noticeContent != null) {
      data.value.noticeContent = data.value.noticeContent.replace(
        /<img/g,
        "<img style='max-width:100%;height:auto;'"
      );
    }
  }
};
getDetail();

//添加浏览记录
const add = async () => {
  const params = {
    noticeId: id,
  };
  await addReadCount(params);
};
add();
</script>
<style lang="scss" scoped>
.container {
  height: calc(100vh - 55px);
  overflow-y: scroll;
  padding: 20px 15px 0 15px;
  &_title {
    font-size: 18px;
    color: #212121;
    font-weight: bold;
  }
  &_date {
    font-size: 12px;
    color: #424242;
    margin-top: 15px;
  }
  &_content {
    margin-top: 20px;
    font-size: 15px;
    color: #424242;
    p {
      margin-bottom: 15px;
    }
  }
}
</style>
