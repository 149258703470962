// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/company_auth.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".container[data-v-6a09b4c6]{position:relative;height:calc(100vh - 3.2rem);overflow-y:scroll}.container_content[data-v-6a09b4c6]{font-size:.42667rem;color:#757575;margin-top:.53333rem;padding-left:.4rem;padding-right:.4rem}.container_content_image[data-v-6a09b4c6]{display:block;text-align:center}.container_content_image_item_upload[data-v-6a09b4c6]{display:flex;justify-content:center;margin:.8rem .8rem .53333rem .8rem}.container_content_image_item_upload_demo[data-v-6a09b4c6]{border-radius:.26667rem;border:.02667rem solid;color:#652363;width:100%;height:100%;padding:.53333rem}.container_content_image_item_upload_demo_pic[data-v-6a09b4c6]{width:7.46667rem;height:5.17333rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:cover}.container_content_image_item_upload_text[data-v-6a09b4c6]{font-size:.42667rem;color:#212121}.container_btn[data-v-6a09b4c6]{position:fixed;bottom:0;width:100%;height:1.70667rem;line-height:.53333rem;background-color:#fff;color:#101010;font-size:.37333rem;text-align:center;box-shadow:0 -.02667rem .02667rem 0 #eee;font-family:Arial}.container_btn .van-button[data-v-6a09b4c6]{margin:.26667rem;width:9.46667rem;height:1.17333rem;border-radius:.16rem;background-color:#652363;text-align:center;color:#fff;font-size:.42667rem;font-family:PingFangSC-semiBold;font-weight:bolder}.van-cell[data-v-6a09b4c6]{padding-left:0;padding-right:0}.van-cell[data-v-6a09b4c6]:after{width:100%;display:inline-block;left:0}", ""]);
// Exports
module.exports = exports;
