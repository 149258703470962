<!--劳动者实名认证-->
<template>
  <div class="container">
    <div class="container_content">
      <!--表单-->
      <div class="container_content_form">
        <van-form>
          <van-cell-group inset>
            <van-field
              name="企业名称"
              label="企业名称"
              v-model="companyName"
              placeholder="请输入"
              input-align="right"
              :rules="[{ required: true, message: '请填写企业名称' }]"
            />
            <van-field
              name="联系人"
              label="联系人"
              v-model="trueName"
              placeholder="请输入"
              input-align="right"
              :rules="[{ required: true, message: '请填写联系人' }]"
            />
            <van-field
              name="联系方式"
              label="联系方式"
              v-model="phone"
              placeholder="请输入"
              input-align="right"
              :rules="[{ required: true, message: '请填写联系方式' }]"
            />
          </van-cell-group>
        </van-form>
      </div>
      <!--图片-->
      <div class="container_content_image">
        <!--身份证正面-->
        <div class="container_content_image_item">
          <div class="container_content_image_item_upload">
            <!-- 外部边框-->
            <div class="container_content_image_item_upload_demo">
              <van-uploader
                accept="image/jpeg,image/png,image/jpg"
                v-model="fileList"
                :max-count="1"
                :max-size="50 * 1024 * 1024"
                :after-read="afterRead"
                @oversize="onOversize"
                @delete="deleteImage"
                :preview-size="[300,210]"
              >
                <div class="container_content_image_item_upload_demo_pic"></div>
              </van-uploader>
            </div>
          </div>
          <!--底下的文字-->
          <div class="container_content_image_item_upload_text">
            上传营业执照
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 底部按钮 -->
  <div class="container_btn">
    <van-button @click="sub" :disabled="clickStatus === '0'"
      >上传认证信息</van-button
    >
  </div>
</template>
<script setup name="CompanyAuth">
import { uploadFiles } from "@/api/upload";
import { companyAuth, getUserInfo } from "@/api/user";
import ImageCompressor from "@/utils/ImageCompressor";
import { ref } from "@vue/reactivity";
import { Dialog, Toast } from "vant";
import { useRouter } from "vue-router";
const router = useRouter();
const companyName = ref("");
const trueName = ref("");
const phone = ref("");
//按钮是否可点击 0 不可点击   1 可以点击
const clickStatus = ref("1");
//营业执照正面
const fileList = ref([]);
const files = ref([]);
const deleteImage = (file, detail) => {
  files.value.splice(detail.index, 1);
};
const sub = async () => {
  if (companyName.value === "") {
    Toast("请填写企业名称");
    return;
  }
  if (trueName.value === "") {
    Toast("请填写联系人");
    return;
  }
  if (phone.value === "") {
    Toast("请填写联系方式");
    return;
  }
  //匹配手机号或座机号
  var patrn = /^1[3456789]\d{9}$/; //手机电话
  var zjdh = /^((0\d{2,3})-)?(\d{7,8})$/; //座机电话
  if (patrn.test(phone.value) == false && zjdh.test(phone.value) == false) {
    Toast("请填写正确的联系方式");
    return;
  }
  const data = {
    trueName: trueName.value,
    companyName: companyName.value,
    businessPic: files.value[0],
    phone: phone.value,
  };
  let response = await companyAuth(data);
  if (response != null && response.code === 200) {
    router.go(-1);
  } else {
    Toast(response.msg);
  }
};
const afterRead = async (file) => {
  let blob = await ImageCompressor(file.file, "blob", 0.1);
  // 此时可以自行将文件上传至服务器
  const formData = new FormData();
  let newFile = new File([blob], file.file.name, {
    type: file.file.type,
    lastModified: Date.now(),
  });
  formData.append("file", newFile);
  let response = await uploadFiles(formData);
  files.value.push(response.url);
};
const onOversize = (file) => {
  alert(JSON.stringify(file));
  Toast("文件大小不能超过30m");
};

//获取用户信息
const getUser = async () => {
  let result = await getUserInfo();
  if (result != null && result.code === 200) {
    if (result.data.authStatus != null && result.data.authStatus !== "") {
      //可以点击
      if (result.data.authStatus === "0" || result.data.authStatus === "3") {
        clickStatus.value = "1";
      } else {
        clickStatus.value = "0";
      }
      if (result.data.remark != null && result.data.authStatus === "3") {
        //如果审核未通过，弹窗提示
        Dialog.confirm({
          title: "提示",
          message: "您的审核未通过:" + result.data.remark,
        })
          .then(async () => {})
          .catch(async () => {});
      }
    }
    if (result.data.companyInfo != null) {
      //企业名称
      if (
        result.data.companyInfo.companyName != null &&
        result.data.companyInfo.companyName !== ""
      ) {
        companyName.value = result.data.companyInfo.companyName;
      }
      //联系人
      if (
        result.data.companyInfo.trueName != null &&
        result.data.companyInfo.trueName !== ""
      ) {
        trueName.value = result.data.companyInfo.trueName;
      }
      //电话
      if (
        result.data.companyInfo.phone != null &&
        result.data.companyInfo.phone !== ""
      ) {
        phone.value = result.data.companyInfo.phone;
      }
      //营业执照照片
      if (
        result.data.companyInfo.businessPic != null &&
        result.data.companyInfo.businessPic !== ""
      ) {
        let item = {};
        item.url = result.data.companyInfo.businessPic;
        fileList.value.push(item);
        files.value.push(result.data.companyInfo.businessPic);
      }
    }
  }
};
getUser();
</script>
<style scoped lang="scss">
.container {
  position: relative;
  height: calc(100vh - 120px);
  overflow-y: scroll;
  &_content {
    font-size: 16px;
    color: #757575;
    margin-top: 20px;
    padding-left: 15px;
    padding-right: 15px;

    &_image {
      display: block;
      text-align: center;
      &_item {
        &_upload {
          display: flex;
          justify-content: center;
          margin: 30px 30px 20px 30px;
          &_demo {
            border-radius: 10px;
            border: 1px solid;
            color: #652363;
            width: 100%;
            height: 100%;
            padding: 20px;
            &_pic {
              width: 280px;
              height: 194px;
              background: url("../../assets/images/company_auth.jpg") no-repeat
                center;
              background-size: cover;
            }
          }
          &_text {
            font-size: 16px;
            color: #212121;
          }
        }
      }
    }
  }
}
.container_btn {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  text-align: center;
  box-shadow: 0px -1px 1px 0px rgba(238, 238, 238, 100);
  font-family: Arial;

  .van-button {
    margin: 10px;
    width: 355px;
    height: 44px;
    border-radius: 6px;
    background-color: #652363;
    text-align: center;
    color: rgba(255, 255, 255, 100);
    font-size: 16px;
    font-family: PingFangSC-semiBold;
    font-weight: bolder;
  }
}
.van-cell {
  padding-left: 0;
  padding-right: 0;
}
.van-cell::after {
  width: 100%;
  display: inline-block;
  left: 0;
}
</style>
